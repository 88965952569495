import React from "react";
import ProductInsightCard from "../HomepageComponents/ProductInsightCard";
import swooshPurple from "../../assets/images/swoosh-purple.webp";

const ProductInsights = () => {
  return (
    <>
      <div className="product-insights-div" id="products">
        <span className="text-with-swoosh-purple">
          {/* <span className="sub-div-heading">Product Insights</span> */}
          <span className="sub-div-heading">Core Capabilities</span>

          <img src={swooshPurple} alt="swoosh" className="swoosh" />
        </span>
        <div className="product-insights-div-flex">
          <ProductInsightCard data-aos={"flip-up"}>
            <div className="product-insight-card-children-div">
              <div className="product-insight-card-img-bg">
                <div className="product-insight-card-image-container">
                  <img
                    src={
                      "https://res.cloudinary.com/papertalk-io/image/upload/v1704566959/jpt91dsrdoq5czlqnxew.webp"
                    }
                    alt="search"
                    className="product-insight-card-img"
                  />
                </div>
              </div>
              <div className="product-insight-text-div">
                <span className="product-insight-card-title">
                  Precision Search
                </span>
                <p>
                  Quickly locate the research papers you need. Our
                  straightforward search tools and filters save you time by
                  bringing you the most relevant results, including searches by
                  author.
                </p>
              </div>
            </div>
          </ProductInsightCard>
          <ProductInsightCard data-aos={"flip-down"}>
            <div className="product-insight-card-children-div">
              <div className="product-insight-card-img-bg">
                <div className="product-insight-card-image-container">
                  <img
                    src={
                      "https://res.cloudinary.com/papertalk-io/image/upload/v1704566959/bavib7nxxkmcbc3yutp6.webp"
                    }
                    alt="search"
                    className="product-insight-card-img"
                  />
                </div>
              </div>
              <div className="product-insight-text-div">
                <span className="product-insight-card-title">
                  AI-Powered Understanding
                </span>
                <p>
                  Choose a paper and get a clear, concise explanation generated
                  by our AI, making it easy to grasp the main points. If you're
                  still curious, our chatbot is here to answer any questions.
                </p>
              </div>
            </div>
          </ProductInsightCard>
          <ProductInsightCard data-aos={"flip-up"}>
            <div className="product-insight-card-children-div">
              <div className="product-insight-card-img-bg">
                <div className="product-insight-card-image-container">
                  <img
                    src={
                      "https://res.cloudinary.com/papertalk-io/image/upload/v1704566960/yhket4n7gnnxw4rlni9e.webp"
                    }
                    alt="search"
                    className="product-insight-card-img"
                  />
                </div>
              </div>
              <div className="product-insight-text-div">
                <span className="product-insight-card-title">
                  Actionable Insights
                </span>
                <p>
                  With a deeper understanding of your chosen papers, we guide
                  you on how to apply these insights practically, whether in
                  furthering your own research or in real-world projects.
                </p>
              </div>
            </div>
          </ProductInsightCard>
        </div>
      </div>
    </>
  );
};

export default ProductInsights;
