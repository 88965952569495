import axios from "axios";
import { logoutAxios } from "../services/Auth.services";

const instance = axios.create({
  headers: {},
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized") {
      await logoutAxios();
      window.localStorage.clear();
      // window.location.href ="/";
    }
    return Promise.reject(error);
  },
);

export default instance;