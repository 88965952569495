import { Icon } from "@iconify/react";
import React from "react";

const RemoveBtn = ({ onClick }) => {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        width: "20px",
        justifyContent: "center",
        height: "20px",
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
        fontSize: "22px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Icon icon="maki:cross" color="white" />
    </button>
  );
};

export default RemoveBtn;
