import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { formatNumber, sanitizePositiveNumber } from "../../utils/textHelpers";

const MinimumCitationCheckbox = ({
  minCitation,
  setCustomCitation,
  setMinCitation,
  minCitationArr,
  customCitation,
}) => {
  return (
    <div className="modal-filter-div">
      <span className="modal-filter-div-title">Minimum Citations</span>
      <div style={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="citation"
          name="radio-buttons-group"
          onChange={(e) => setMinCitation(e.target.value)}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            width: "100%",
          }}
        >
          {minCitationArr.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                checked={item === minCitation}
                value={item}
                control={
                  <Radio
                    style={{
                      color: "#4918A6",
                    }}
                  />
                }
                label={formatNumber(item)}
              />
              {minCitation === "Custom" && item === "Custom" && (
                <input
                  className="authorCitationInput"
                  style={{ maxWidth: "120px" }}
                  placeholder="Enter custom range"
                  onChange={(e) =>
                    setCustomCitation(sanitizePositiveNumber(e.target.value))
                  }
                  value={customCitation}
                />
              )}
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default MinimumCitationCheckbox;
