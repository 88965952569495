import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerByMode = ({
  publicationDateOrYearFormat,
  publicationDateOrYearValue,
  setPublicationDateOrYearValue,
  setPublicationDateOrYearError,
}) => {
  return publicationDateOrYearFormat === "Exact Date Search" ? (
    <DatePicker
      dateFormat={"yyyy-MM-dd"}
      className="datePickerInput"
      placeholderText="Exact Date Search"
      showYearDropdown
      yearDropdownItemNumber={5}
      selected={publicationDateOrYearValue.startDate}
      onChange={(date) => {
        setPublicationDateOrYearValue({
          ...publicationDateOrYearValue,
          startDate: date,
        });
        setPublicationDateOrYearError(false);
      }}
    />
  ) : publicationDateOrYearFormat === "Date Range" ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <DatePicker
        placeholderText="Start Date"
        showYearDropdown
        yearDropdownItemNumber={5}
        dateFormat={"yyyy-MM-dd"}
        className="datePickerInput"
        selected={publicationDateOrYearValue.startDate}
        onChange={(date) => {
          setPublicationDateOrYearValue({
            ...publicationDateOrYearValue,
            startDate: date,
          });
          setPublicationDateOrYearError(false);
        }}
        selectsStart
        startDate={publicationDateOrYearValue.startDate}
        endDate={publicationDateOrYearValue.endDate}
      />
      <DatePicker
        placeholderText="End Date"
        showYearDropdown
        yearDropdownItemNumber={5}
        dateFormat={"yyyy-MM-dd"}
        className="datePickerInput"
        selected={publicationDateOrYearValue.endDate}
        onChange={(date) => {
          setPublicationDateOrYearValue({
            ...publicationDateOrYearValue,
            endDate: date,
          });
          setPublicationDateOrYearError(false);
        }}
        selectsEnd
        startDate={publicationDateOrYearValue.startDate}
        endDate={publicationDateOrYearValue.endDate}
        minDate={publicationDateOrYearValue.startDate}
      />
    </div>
  ) : publicationDateOrYearFormat === "Year Range" ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <DatePicker
        selected={publicationDateOrYearValue.startDate}
        showYearDropdown
        yearDropdownItemNumber={5}
        className="datePickerInput"
        onChange={(date) => {
          setPublicationDateOrYearValue({
            ...publicationDateOrYearValue,
            startDate: date,
          });
          setPublicationDateOrYearError(false);
        }}
        selectsEnd
        startDate={publicationDateOrYearValue.startDate}
        endDate={publicationDateOrYearValue.endDate}
        dateFormat="yyyy"
        showYearPicker
        placeholderText="Start Year"
      />
      <DatePicker
        selected={publicationDateOrYearValue.endDate}
        showYearDropdown
        yearDropdownItemNumber={5}
        className="datePickerInput"
        onChange={(date) => {
          setPublicationDateOrYearValue({
            ...publicationDateOrYearValue,
            endDate: date,
          });
          setPublicationDateOrYearError(false);
        }}
        selectsEnd
        startDate={publicationDateOrYearValue.startDate}
        endDate={publicationDateOrYearValue.endDate}
        dateFormat="yyyy"
        showYearPicker
        placeholderText="End Year"
      />
    </div>
  ) : null;
};

export default DatePickerByMode;
