import instance from "../axios/interceptor";

export const uploadPDFService = async (formData) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/upload`,
    {
      withCredentials: true,
      data: formData,
      method: "POST",
    },
  );
  return response;
};

export const fetchUnderstandingPDF = async (id) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/upload/sementic/${id}`,
    {
      withCredentials: true,
      method: "POST",
    },
  );
  return response;
};
