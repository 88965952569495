import { Icon } from "@iconify/react";
import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "../assets/css/ChatBot.css";
import avatar from "../assets/images/chatbot.jpeg";
import { fetchChatBot } from "../services/PDF.services";
import Loader from "./Loader";
import Swal from "sweetalert2";

export const ChatBot = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sementicScholarPaperId = searchParams.get("sementicScholarPaperId");
  const paperId = searchParams.get("paperId");
  const [open, setOpen] = React.useState(false);
  const [initialLoader, setInitialLoader] = React.useState(true);
  const [typing, setTyping] = React.useState(false);

  const [question, setQuestion] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);

  const askQuestions = async (e, questionAsked) => {
    e.preventDefault();
    try {
      if (questionAsked.trim().length > 0) {
        setTyping(true);
        setQuestions((current) => [
          {
            role: "user",
            text: questionAsked,
            time: new Date().getTime(),
          },
          ...current,
        ]);
        setQuestion("");
        const response = await fetchChatBot(
          paperId,
          sementicScholarPaperId,
          questionAsked,
        );
        setTyping(false);
        const data = await response?.data;
        setQuestions(data?.messages?.reverse() || []);
        if (response?.status === 403) {
          setTimeout(() => setOpen(false), 500);
        }
      }
    } catch (err) {
      setTyping(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data?.error || "Something went wrong!.",
      });
      setQuestions((current) => [
        {
          role: "assistant",
          text: "Some Error occurred, sorry for inconvinience.",
          time: new Date().getTime(),
        },
        ...current,
      ]);
    }
  };

  React.useEffect(() => {
    const fetchHistory = async () => {
      try {
        setQuestions([]);
        setInitialLoader(true);
        const response = await fetchChatBot(
          paperId,
          sementicScholarPaperId,
          "",
        );
        setTyping(false);
        const data = await response?.data;
        setInitialLoader(false);
        setQuestions(data?.messages?.reverse() || []);
      } catch (err) {
        setInitialLoader(false);
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.error || "Something went wrong!.",
        });
      }
    };
    fetchHistory();
    // eslint-disable-next-line
  }, [window.location.pathname]);

  React.useMemo(() => {
    if (open) document.body.classList.add("hovered");
    else document.body.classList.remove("hovered");
  }, [open]);

  const groupByDate = (messages) => {
    const grouped = {};
    messages.forEach((msg) => {
      const date = moment(msg.timestamp).format("YYYY-MM-DD");
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(msg);
    });
    return grouped;
  };

  const groupedMessages = React.useMemo(() => {
    return groupByDate(questions);
  }, [questions]);

  return (
    <div className="chat_bot_fixed_main_div">
      <div
        className="chat_bot_chat_div"
        style={{ width: "100%", height: "100%" }}
      >
        {initialLoader && <Loader />}
        <div className="chatbox_chat_div">
          {questions.length === 0 && (
            <div className="chatbot-initial-div">
              <img src={avatar} alt="chatbot" />
              <h4>How can I help you today?</h4>
              <div className="chatbot-suggestions">
                <div
                  className="chatbot-suggestion-div"
                  onClick={(e) => askQuestions(e, "What is the conclusion?")}
                >
                  1. What is the conclusion?
                </div>
                <div
                  className="chatbot-suggestion-div"
                  onClick={(e) => askQuestions(e, "Who is the author?")}
                >
                  2. Who is the author?
                </div>
                <div
                  className="chatbot-suggestion-div"
                  onClick={(e) =>
                    askQuestions(e, "What is the aim of this research?")
                  }
                >
                  3. What is the aim of this research?
                </div>
                <div
                  className="chatbot-suggestion-div"
                  onClick={(e) =>
                    askQuestions(e, "Please provide brief overview")
                  }
                >
                  4. Please provide brief overview
                </div>
              </div>
            </div>
          )}
          {typing && (
            <div className="chatbox_chatbot_msg_div">
              <div className="tiblock">
                <div className="tidot"></div>
                <div className="tidot"></div>
                <div className="tidot"></div>
              </div>
            </div>
          )}
          <br />
          {Object.entries(groupedMessages).map(([date, messages], index) => (
            <React.Fragment key={index}>
              {messages.map((chat, index) => (
                <React.Fragment key={index}>
                  {chat.role === "user" ? (
                    <div
                      key={index}
                      className={
                        chat.role === "user"
                          ? "chatbox_user_msg_div"
                          : "chatbox_chatbot_msg_div"
                      }
                    >
                      <div
                        className={chat.role === "user" ? "typing-demo" : ""}
                      >
                        {chat.text}
                        <br />
                        <div className="chat-bot-timestamp">
                          <span>{moment(chat?.timestamp).format("LT")}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="message-div"
                      key={index}
                      style={{ alignItems: "flex-end" }}
                    >
                      <div
                        className="message-div-avatar"
                        style={{ width: "30px", height: "30px", margin: "0px" }}
                      >
                        <img
                          alt="avatar"
                          src={avatar}
                          className="message-avatar-img"
                        />
                      </div>
                      <div className="chatbot-bubble">
                        <span className="chatbot-bubble-user-name">
                          {"PaperTalkBot"}
                        </span>
                        <span className="chatbot-bubble-message">
                          {chat.text}
                        </span>
                        <br />
                        <div
                          className="chat-bot-timestamp"
                          style={{ color: "#666668" }}
                        >
                          <span>{moment(chat?.timestamp).format("LT")}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <br />
                </React.Fragment>
              ))}
              <div className="chat-bot-date-divider" key={index}>
                {moment(date).format("MM/DD/YYYY")}
              </div>
            </React.Fragment>
          ))}
        </div>
        <form
          id="chatbot-chat"
          className="chatbox_input_div"
          onSubmit={(e) => askQuestions(e, question)}
        >
          <input
            className="chatbox_input"
            mutliline={"true"}
            placeholder={"Type your query ..."}
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
            height={"fit-content"}
          />
          <button
            type="submit"
            className="chatbox_input_btn"
            onClick={(e) => askQuestions(e, question)}
          >
            <Icon icon="ion:send" color="white" style={{ fontSize: "20px" }} />
          </button>
        </form>
      </div>
    </div>
  );
};
