export function formatDate(inputDate, format) {
  // Convert input string to a Date object
  if (!inputDate) {
    return false;
  }
  const date = new Date(inputDate);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const year = date.getFullYear();

  // Format the date components with leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Return the formatted date
  if (format === "Exact Date Search")
    return `${year}-${formattedMonth}-${formattedDay}`;
  else if (format === "Date Range")
    return `${year}-${formattedMonth}-${formattedDay}`;
  else if (format === "Year Range") return `${year}`;
  else return false;
}
