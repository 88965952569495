import instance from "../axios/interceptor";

export const fetchResearchPaperDetails = async (
  paperId,
  sementicScholarPaperId,
) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/analysis/${
      paperId === "undefined" ? "sementic/" : ""
    }${paperId !== "undefined" ? paperId : sementicScholarPaperId}`,
    {
      withCredentials: true,
    },
  );
  return response;
};

export const fetchPublicPDFService = async (publicUrl) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_API}/papers/pdf/${encodeURIComponent(
      publicUrl,
    )}`,
  );
  return response;
};
export const fetchPDFDetails = async (paperId) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/search/paper/${paperId}`,
    {
      withCredentials: true,
    },
  );
  return response;
};

export const fetchRecommendationsOrReferencesOrCitation = async (
  paperId,
  mode,
  limit,
) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/search/paper/${paperId}/${mode}?limit=${limit}`,
    {
      withCredentials: true,
    },
  );
  return response;
};

export const fetchChatBot = async (
  paperId,
  sementicScholarPaperId,
  question,
) => {
  let idType = "sementicScholarPaperId";
  let id = sementicScholarPaperId;
  if (paperId !== "undefined") {
    idType = "paperId";
    id = paperId;
  }
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/papers/${id}/chat?idType=${idType}`,
    {
      withCredentials: true,
      method: "POST",
      data: {
        text: question,
      },
    },
  );
  return response;
};
