import React from "react";
import advancedFeaturePoster from "../../assets/images/advanced-feature-poster.webp";

const AdvancedFeatures = () => {
  const [activePoster, setActivePoster] = React.useState({
    id: 1,
    button: "Intelligent Chatbot",
    title:
      "Meet your new research confidant, the Intelligent Chatbot, always on call to guide you through the maze of academia. Pose your questions, big or small, and receive instant, precise explanations. It's like having a personal research assistant available around the clock, ensuring no query goes unanswered on your path to enlightenment.",
    img: "https://res.cloudinary.com/papertalk-io/image/upload/v1704566958/entw9scimwo0ycan5upb.webp",
  });
  const advancedFeatures = [
    {
      id: 1,
      button: "Intelligent Chatbot",
      title:
        "Meet your new research confidant, the Intelligent Chatbot, always on call to guide you through the maze of academia. Pose your questions, big or small, and receive instant, precise explanations. It's like having a personal research assistant available around the clock, ensuring no query goes unanswered on your path to enlightenment.",
      img: "https://res.cloudinary.com/papertalk-io/image/upload/v1704566958/entw9scimwo0ycan5upb.webp",
    },
    {
      id: 2,
      button: "Audiobook Access",
      title:
        "Transform idle moments into chapters of learning with Audiobook Access. Whether you're commuting or simply taking a break, let the world of research unfold through the spoken word. Absorb the latest studies with the ease of listening, making the most of every minute in your pursuit of knowledge.",
      img: "https://res.cloudinary.com/papertalk-io/image/upload/v1704566958/ldh5mxkoovyvdpdmzhph.webp",
    },
    {
      id: 3,
      button: "Smart Search",
      title:
        "Welcome to a smarter way to discover with Papertalk.io. Navigate the vast landscape of research using advanced filters like venues, publication dates, citation counts, and author names. It’s a breeze to land the perfect paper that aligns with your quest for knowledge. Save precious time and boost your productivity.",
      img: "https://res.cloudinary.com/papertalk-io/image/upload/v1704566958/dzdhoaucl1e8r7bda4xs.webp",
    },
    {
      id: 4,
      button: "Personalized Library",
      title:
        "Embark on a voyage of knowledge with your very own Personalized Library. Here, your discoveries aren't just stored; they're curated into a living archive of wisdom. Imagine a space where every paper tells a story of innovation, tailored just for you. Revisit the narratives that shaped your research and continue to inspire your academic quest.",
      img: advancedFeaturePoster,
    },
  ];

  return (
    <div className="advance-features-div">
      <span className="text-with-swoosh-purple">
        <span className="sub-div-heading" style={{ color: "#fff" }}>
          Elevate Knowledge: Next-Gen Tools for Researchers
        </span>
      </span>
      <span
        style={{
          color: "#fff",
          fontSize: "20px",
          display: "block",
          marginTop: "0px",
        }}
      >
        Develop Expertise: Innovative Tools to Lead Research
      </span>
      <div className="advanced-features-buttons-flex">
        {advancedFeatures.map((obj) => (
          <div
            key={obj.id}
            className={
              obj.id === activePoster?.id
                ? "advanced-features-button-active"
                : "advanced-features-button"
            }
            onClick={() => setActivePoster(obj)}
          >
            {obj.button}
          </div>
        ))}
      </div>
      <div className="text-flex-img-div">
        <p>{activePoster.title}</p>
        <img
          src={activePoster.img}
          alt="poster"
          className="text-flex-img-div-img"
        />
      </div>
    </div>
  );
};

export default AdvancedFeatures;
