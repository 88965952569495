import instance from "../axios/interceptor";

export const profileDetails = async () => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/me`,
    {
      withCredentials: true,
    },
  );
  return response;
};

export const logoutAxios = async () => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/logout`,
    {
      withCredentials: true,
    },
  );
  return response;
};
