import React from "react";
import "../../assets/css/MessageDiv.css";
import u1 from "../../assets/images/user(1).webp";
import u2 from "../../assets/images/user(2).webp";
import u3 from "../../assets/images/user(3).webp";
import u4 from "../../assets/images/user(4).webp";
import u5 from "../../assets/images/user(5).webp";
import u6 from "../../assets/images/user(6).webp";
import u7 from "../../assets/images/user(7).webp";
import { Rating } from "@mui/material";

const MessageDiv = ({ index, name, message, role, value }) => {
  const profiles = [u1, u2, u3, u4, u5, u6, u7];

  return (
    <div className="message-div">
      <div className="message-div-avatar">
        <img
          alt="avatar"
          src={profiles[index % 7]}
          className="message-avatar-img"
        />
      </div>
      <div className="bubble">
        <div className="bubble-rating-div">
          {/* <Icon icon="mingcute:star-fill" color="gold" />
          <Icon icon="mingcute:star-fill" color="gold" />
          <Icon icon="mingcute:star-fill" color="gold" />
          <Icon icon="mingcute:star-fill" color="gold" />
          <Icon icon="mingcute:star-fill" color="gold" /> */}
          <Rating name="read-only" value={value} readOnly />
        </div>
        <span className="bubble-message">"{message}"</span>
        {name && <span className="bubble-user-name">{name}</span>}
        <span className="bubble-user-role">{role}</span>
      </div>
    </div>
  );
};

export default MessageDiv;
