import { Icon } from "@iconify/react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import React, { useState, useRef } from "react";
import "../assets/css/AudioPlayer.css";

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  color: "#fff",
  fontWeight: 500,
  letterSpacing: 0.2,
});
const ITEM_HEIGHT = 48;

const playbackSpeeds = [
  {
    id: 1,
    label: "1x",
    value: "1",
  },
  {
    id: 2,
    label: "1.25x",
    value: 1.25,
  },
  {
    id: 3,
    label: "1.5x",
    value: 1.5,
  },
  {
    id: 4,
    label: "1.75x",
    value: 1.75,
  },
  {
    id: 5,
    label: "2x",
    value: 2,
  },
];

const AudioPlayer = ({ src, mode = "light" }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [speed, setSpeed] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute < 10 ? `0${minute}` : minute}:${
      secondLeft < 10
        ? `0${Math.round(Number(secondLeft))}`
        : Math.round(Number(secondLeft))
    }`;
  }

  const togglePlay = () => {
    if (!src) return;
    setIsPlaying((prev) => !prev);
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handleSpeedChange = (value) => {
    const newSpeed = parseFloat(value);
    setSpeed(newSpeed);
    audioRef.current.playbackRate = newSpeed;
    handleClose();
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    if (audioRef.current.currentTime === duration) setIsPlaying(false);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleTimelineChange = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    audioRef.current.currentTime = newTime;
  };

  return (
    <div className="audio-player-main-div">
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <div>
        <button className="audio-player-btn" onClick={togglePlay}>
          {isPlaying ? (
            <Icon
              icon="mingcute:pause-fill"
              color={mode === "dark" ? "black" : "white"}
            />
          ) : (
            <Icon
              icon="mingcute:play-fill"
              color={mode === "dark" ? "black" : "white"}
            />
          )}
        </button>
      </div>
      <div
        className="audio-player-timeline"
        style={{ width: mode === "dark" ? "80%" : "50%" }}
      >
        <Slider
          aria-label="time-indicator"
          size="small"
          value={currentTime}
          min={0}
          step={1}
          max={duration}
          onChange={handleTimelineChange}
          sx={{
            color: mode === "dark" ? "#000" : "#fff",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 8,
              height: 8,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`,
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: -2,
          }}
        >
          <TinyText style={{ color: mode === "dark" ? "#000" : "#fff" }}>
            {formatDuration(currentTime)}
          </TinyText>
          <TinyText style={{ color: mode === "dark" ? "#000" : "#fff" }}>
            {formatDuration(duration)}
          </TinyText>
        </Box>
      </div>
      <div
        className="audio-player-volume"
        style={{ width: mode === "dark" ? "40%" : "20%" }}
      >
        <Icon
          icon="fa6-solid:volume-low"
          color={mode === "dark" ? "black" : "white"}
        />
        <Slider
          size="small"
          aria-label="Volume"
          min={0}
          max={1}
          step={0.1}
          value={volume}
          onChange={handleVolumeChange}
          sx={{
            color: mode === "dark" ? "#000" : "#fff",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 8,
              height: 8,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`,
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
            },
          }}
        />
        <Icon
          icon="fa6-solid:volume-high"
          color={mode === "dark" ? "black" : "white"}
        />
      </div>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
              flexDirection: "column",
            }}
          >
            <Icon
              icon="material-symbols:speed"
              color={mode === "dark" ? "black" : "white"}
            />
            <span
              style={{
                color: mode === "dark" ? "#000" : "#fff",
                fontSize: "9px",
              }}
            >
              {speed}x
            </span>
          </div>
        </IconButton>
        <Menu
          color="secondary"
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "60px",
            },
          }}
        >
          {playbackSpeeds.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.value === speed}
              onClick={() => handleSpeedChange(option.value)}
            >
              <span style={{ fontSize: "14px" }}>{option.label}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default AudioPlayer;
