import * as React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "../../assets/css/RequestFeedbackForm.css";
import { Icon } from "@iconify/react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Rating,
  Slider,
} from "@mui/material";
import Swal from "sweetalert2";
import { addRequestForm } from "../../services/Feedback.service";

const style = {
  position: "absolute",
  top: window.innerWidth > 500 ? "55%" : "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth - 20,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  outline: "none",
  zIndex: "0",
  backgroundColor: "#fff",
  borderRadius: "13px",
  maxWidth: "900px",
  maxHeight: "90vh",
};

export default function RequestFeedbackForm({
  //   children,
  open,
  handleClose,
  //   searchType,
  //   fetchAPI,
}) {
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [professionOthersText, setProfessionOthersText] = React.useState("");
  const usageEnum = ["daily", "someTimeInWeek", "weekly", "monthly", "rarely"];
  const [professionBuilding, setProfessionBuilding] = React.useState("");
  const [subscription, setSubscription] = React.useState("");
  const [oftenUse, setOftenUse] = React.useState(3);
  const [accuracy, setAccuracy] = React.useState("");
  const [primaryUsage, setPrimaryUsage] = React.useState("");
  const [primaryUsageOthersText, setPrimaryUsageOthersText] =
    React.useState("");
  const [easeOfNavigation, setEaseOfNavigation] = React.useState("");
  const [feedbackText, setFeedbackText] = React.useState("");

  const [feedbackFormError, setFeedbackFormError] = React.useState({
    country: false,
    city: false,
    profession: false,
    professionOthersText: false,
    primaryUsageOthersText: false,
    professionBuilding: false,
    subscription: false,
    oftenUse: false,
    accuracy: false,
    primaryUsage: false,
    easeOfNavigation: false,
  });

  const handleSubmit = async () => {
    try {
      setFeedbackFormError({
        country: !country,
        city: !city,
        profession: !profession,
        professionOthersText: !professionOthersText,
        professionBuilding: !professionBuilding,
        subscription: !subscription,
        oftenUse: false,
        accuracy: !accuracy,
        primaryUsage: !primaryUsage,
        primaryUsageOthersText: !primaryUsageOthersText,
        easeOfNavigation: !easeOfNavigation,
      });
      if (
        country &&
        city &&
        profession &&
        (profession === "Other (Please Specify)"
          ? professionOthersText.trim().length > 0
          : true) &&
        (primaryUsage === "Other (Please Specify)"
          ? primaryUsageOthersText.trim().length > 0
          : true) &&
        professionBuilding &&
        subscription &&
        oftenUse &&
        accuracy &&
        primaryUsage &&
        easeOfNavigation
      ) {
        const formData = new FormData();
        formData.append("rating", 1);
        formData.append(
          "purpose",

          primaryUsage === "Other (Please Specify)"
            ? primaryUsageOthersText
            : primaryUsage,
        );
        formData.append(
          "payInFuture",
          subscription === "Yes"
            ? true
            : subscription === "No"
              ? false
              : "maybe",
        );
        formData.append(
          "profession",
          profession === "Other (Please Specify)"
            ? professionOthersText
            : profession,
        );
        formData.append("professionDetail", professionBuilding);
        formData.append("suggestion", feedbackText);
        formData.append("country", country);
        formData.append("state", city);
        formData.append("freqOfUse", usageEnum[oftenUse - 1]);
        formData.append("uxExpereince", easeOfNavigation);
        formData.append("accuracy", accuracy);
        const response = await addRequestForm(formData);
        Swal.fire("Success", response.data?.message, "success").then(() =>
          handleClose(),
        );
      }
    } catch (err) {
      Swal.fire("Error", err?.response?.data, "error").then(() =>
        handleClose(),
      );
    }
  };

  React.useEffect(() => {
    setCountry("");
    setCity("");
    setProfession("");
    setProfessionBuilding("");
    setProfessionOthersText("");
    setPrimaryUsageOthersText("");
    setSubscription("");
    setOftenUse(3);
    setAccuracy("");
    setPrimaryUsage("");
    setEaseOfNavigation("");
    setFeedbackText("");
  }, [open]);

  const returnAppreciation = (value, arr) => {
    if (value <= 0 || value > 5 || !value) return "";
    else return arr[value - 1];
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setOftenUse(newValue);
      setFeedbackFormError({
        ...feedbackFormError,
        oftenUse: false,
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal-header">
              <div className="modal-header-title">
                <span>Increase Limit Request Form</span>
              </div>
              <div
                className="modal_close"
                style={{ marginLeft: "auto" }}
                onClick={() => handleClose()}
              >
                <Icon icon="maki:cross" />
              </div>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: "70vh !important" }}
            >
              <div className="upgrade-modal-main">
                <div style={{ width: "calc(100% - 40px)" }}>
                  <div className="survey-plans-flex">
                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          1. Your Current Location
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          columnGap: "15px",
                          flexWrap: "wrap",
                        }}
                      >
                        <CountryDropdown
                          classes="feedback-form-select"
                          value={country}
                          onChange={(val) => {
                            setFeedbackFormError({
                              ...feedbackFormError,
                              country: false,
                            });
                            setCountry(val);
                          }}
                        />
                        <RegionDropdown
                          classes="feedback-form-select"
                          blankOptionLabel="Select State/Region"
                          country={country}
                          value={city}
                          onChange={(val) => {
                            setFeedbackFormError({
                              ...feedbackFormError,
                              city: false,
                            });
                            setCity(val);
                          }}
                        />
                      </div>
                      {(feedbackFormError.country ||
                        feedbackFormError.city) && (
                        <FormHelperText error={true}>
                          Please select your current location.
                        </FormHelperText>
                      )}
                    </div>
                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          2. Your Profession/Area of Study
                        </h4>
                      </div>
                      <div>
                        <FormControl>
                          <RadioGroup
                            onChange={(e) => {
                              setFeedbackFormError({
                                ...feedbackFormError,
                                profession: false,
                              });
                              setProfession(e.target.value);
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            {[
                              "Student",
                              "Researcher",
                              "Educator",
                              "Industry Professional",
                              "Other (Please Specify)",
                            ].map((option, index) => (
                              <FormControlLabel
                                key={index}
                                value={option}
                                control={<Radio style={{ color: "#41169c" }} />}
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                          {profession === "Other (Please Specify)" && (
                            <div>
                              <input
                                className="feedback-form-input"
                                type={"text"}
                                value={professionOthersText}
                                placeholder={"Please share your area of study"}
                                onChange={(e) => {
                                  setFeedbackFormError({
                                    ...feedbackFormError,
                                    professionOthersText:
                                      e.target.value.trim().length === 0,
                                  });
                                  setProfessionOthersText(e.target.value);
                                }}
                              />
                              {feedbackFormError.professionOthersText && (
                                <FormHelperText error={true}>
                                  Please share your area of study.
                                </FormHelperText>
                              )}
                            </div>
                          )}
                        </FormControl>
                      </div>
                      {feedbackFormError.profession && (
                        <FormHelperText error={true}>
                          Please select your profession / area of study.
                        </FormHelperText>
                      )}
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          3.{" "}
                          {profession === "Student" || profession === "Educator"
                            ? "Please enter your School or University."
                            : profession === "Researcher" ||
                                profession === "Industry Professional"
                              ? "Please enter your Organization or Company."
                              : "Your Organization/School"}
                        </h4>
                      </div>
                      <div>
                        <FormControl>
                          <input
                            className="feedback-form-input"
                            type={"text"}
                            value={professionBuilding}
                            placeholder={""}
                            onChange={(e) => {
                              setFeedbackFormError({
                                ...feedbackFormError,
                                professionBuilding:
                                  e.target.value.trim().length === 0,
                              });
                              setProfessionBuilding(e.target.value);
                            }}
                          />
                          {feedbackFormError.professionBuilding && (
                            <FormHelperText error={true}>
                              {profession === "Student" ||
                              profession === "Educator"
                                ? "Please enter your School or University."
                                : profession === "Researcher" ||
                                    profession === "Industry Professional"
                                  ? "Please enter your Organization or Company."
                                  : "Your Organization/School"}{" "}
                              is required.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          4. Would you be willing to pay for access to
                          Papertalk.io's features?
                        </h4>
                      </div>
                      <div>
                        <FormControl>
                          <RadioGroup
                            onChange={(e) => {
                              setFeedbackFormError({
                                ...feedbackFormError,
                                subscription: false,
                              });
                              setSubscription(e.target.value);
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            {["Yes", "Maybe in the future", "No"].map(
                              (option, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={option}
                                  control={
                                    <Radio style={{ color: "#41169c" }} />
                                  }
                                  label={option}
                                />
                              ),
                            )}
                          </RadioGroup>
                        </FormControl>
                        {feedbackFormError.subscription && (
                          <FormHelperText error={true}>
                            Please select the payment desire.
                          </FormHelperText>
                        )}
                      </div>
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          5. How often do you use Papertalk.io for your research
                          needs?
                        </h4>
                      </div>
                      <div
                        style={{
                          maxWidth: "600px",
                          margin: "auto",
                          marginTop: "15px",
                        }}
                      >
                        <Slider
                          size={"medium"}
                          style={{ color: "#41169c" }}
                          aria-labelledby="track-false-slider"
                          onChange={handleChange}
                          defaultValue={3}
                          max={5}
                          min={1}
                          step={1}
                          marks={[
                            { value: 1, label: "Daily" },
                            { value: 2, label: "Several times a week" },
                            { value: 3, label: "Weekly" },
                            { value: 4, label: "Monthly" },
                            { value: 5, label: "Rarely" },
                          ]}
                        />
                      </div>
                      {feedbackFormError.oftenUse && (
                        <FormHelperText error={true}>
                          Please select the usage.
                        </FormHelperText>
                      )}
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          6. How would you rate the accuracy and reliability of
                          information on Papertalk.io?
                        </h4>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          width: "100%",
                          columnGap: "15px",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          name="simple-controlled"
                          // value={accuracy}
                          size={"large"}
                          onClick={(event) => {
                            setFeedbackFormError({
                              ...feedbackFormError,
                              accuracy: false,
                            });
                            setAccuracy(event.target.value);
                          }}
                        />
                        <span
                          style={{
                            color: "#41169C",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                        >
                          {returnAppreciation(accuracy, [
                            "Not Accurate",
                            "Little",
                            "Moderate",
                            "Accurate",
                            "Very Accurate",
                          ])}
                        </span>
                      </div>
                      {feedbackFormError.accuracy && (
                        <FormHelperText error={true}>
                          Please select the accuracy.
                        </FormHelperText>
                      )}
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          7. Primary Purpose of Using Papertalk.io
                        </h4>
                      </div>
                      <div>
                        <FormControl>
                          <RadioGroup
                            onChange={(e) => {
                              setFeedbackFormError({
                                ...feedbackFormError,
                                primaryUsage: false,
                              });
                              setPrimaryUsage(e.target.value);
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            {[
                              "Academic Research",
                              "Professional Development",
                              "Personal Interest",
                              "Other (Please Specify)",
                            ].map((option, index) => (
                              <FormControlLabel
                                key={index}
                                value={option}
                                control={<Radio style={{ color: "#41169c" }} />}
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                          {primaryUsage === "Other (Please Specify)" && (
                            <div>
                              <input
                                className="feedback-form-input"
                                type={"text"}
                                value={primaryUsageOthersText}
                                placeholder={"Please share your purpose"}
                                onChange={(e) => {
                                  setFeedbackFormError({
                                    ...feedbackFormError,
                                    primaryUsageOthersText: false,
                                  });
                                  setPrimaryUsageOthersText(e.target.value);
                                }}
                              />
                              {feedbackFormError.primaryUsageOthersText && (
                                <FormHelperText error={true}>
                                  Please share your purpose.
                                </FormHelperText>
                              )}
                            </div>
                          )}
                        </FormControl>
                        {feedbackFormError.primaryUsage && (
                          <FormHelperText error={true}>
                            Please select any option.
                          </FormHelperText>
                        )}
                      </div>
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          8. Ease of Navigation and User Interface
                        </h4>
                      </div>
                      <div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            width: "100%",
                            columnGap: "15px",
                            alignItems: "center",
                          }}
                        >
                          <Rating
                            name="simple-controlled"
                            // value={easeOfNavigation}
                            size={"large"}
                            onClick={(event) => {
                              setFeedbackFormError({
                                ...feedbackFormError,
                                easeOfNavigation: false,
                              });
                              setEaseOfNavigation(event.target.value);
                            }}
                          />
                          <span
                            style={{
                              color: "#41169C",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            {returnAppreciation(easeOfNavigation, [
                              "Very Difficult",
                              "Difficult",
                              "Moderate",
                              "Easy",
                              "Very Easy",
                            ])}
                          </span>
                        </div>
                      </div>
                      {feedbackFormError.easeOfNavigation && (
                        <FormHelperText error={true}>
                          Please select any star.
                        </FormHelperText>
                      )}
                    </div>

                    <div className="survey-element">
                      <div className="survey-flex-card-title">
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
                          9. Feedback and Improvement Suggestions
                        </h4>
                      </div>
                      <div>
                        <FormControl>
                          <textarea
                            className="feedback-form-textfield"
                            style={{
                              marginTop: "10px",
                              minWidth: "280px",
                              maxWidth: "600px",
                            }}
                            type={"text"}
                            value={feedbackText}
                            placeholder={""}
                            onChange={(e) => setFeedbackText(e.target.value)}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="submit-form-btn" onClick={handleSubmit}>
                Submit Feedback
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
