import { Icon } from "@iconify/react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Popover,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import "../assets/css/SearchPage.css";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import TransitionsModal from "../components/modals";
import Paginator from "../components/Paginator";
import SearchCard from "../components/SearchpageComponents/SearchCard";
import {
  academicDisciplines,
  journals,
  minCitationArr,
  publicationDateAndYearArr,
  publicationTypesArr,
  sortOptions,
  sortOptionsAuthor,
} from "../constants/filtersData";
import {
  getAuthorPapersResults,
  getSearchResults,
  getSuggestionsResults,
} from "../services/Search.sevices";
import { formatNumber } from "../utils/textHelpers";
import { formatDate } from "../utils/dateFormatter";
import PublicationTypes from "../components/FilterComponents/PublicationTypes";
import VenuesFilter from "../components/FilterComponents/VenuesFilter";
import FieldsOfStudy from "../components/FilterComponents/FieldsOfStudy";
import MinimumCitationCheckbox from "../components/FilterComponents/MinimumCitationCheckbox";
import PublicationYear from "../components/FilterComponents/PublicationYear";
import AuthorCard from "../components/SearchpageComponents/AuthorCard";
import AuthorCitationRange from "../components/FilterComponents/AuthorCitationRange";
import AuthorPageCountRange from "../components/FilterComponents/AuthorPageCountRange";
import RemoveBtn from "../components/FilterComponents/RemoveBtn";
import FilterTag from "../components/FilterComponents/FilterTag";
import { uploadPDFService } from "../services/UploadPDF.services";
import { useNavigate } from "react-router";
import SimpleBackdrop from "../components/Backdrop";
import FilterTagWithModal from "../components/FilterDialogBox";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

const useClickOutside = (handler) => {
  let domNode = React.useRef(null);

  React.useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const SearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const type = searchParams.get("type");
  const authorId = searchParams.get("authorId");
  const queryParam = searchParams.get("query");
  const authorName = searchParams.get("authorName");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [limit] = React.useState(20);
  const [pageCount, setPageCount] = React.useState(1);
  const [authorPageCount, setAuthorPageCount] = React.useState(1);
  const [spinner, setSpinner] = React.useState(true);
  const [uploading, setUploading] = React.useState(false);
  const [uploadPDF, setUploadPDF] = React.useState(null);
  const [resetTracker, setResetTracker] = React.useState({
    publicationTypes: false,
    fieldsOfStudy: false,
    venues: false,
    publicationDateOrYearValue: false,
    openAccessPDF: false,
    citationCount: false,
    authorPageCountRange: false,
    authorCitationRange: false,
  });
  const [showTags, setShowTags] = React.useState({
    publicationTypes: false,
    fieldsOfStudy: false,
    venues: false,
    publicationDateOrYearValue: false,
    openAccessPDF: type === "paper",
    citationCount: false,
    authorPageCountRange: false,
    authorCitationRange: false,
  });
  const [openFilter, setOpenFilter] = React.useState(false);
  const [authorCitationRange, setAuthorCitationRange] = React.useState({
    min: 0,
    max: null,
  });
  const [authorPapers, setAuthorPapers] = React.useState([]);
  const [showAuthorPapersShowMore, setShowAuthorPapersShowMore] =
    React.useState(true);
  const [authorPapersShowMoreLoader, setShowAuthorPapersShowMoreLoader] =
    React.useState(false);
  const [authorPageCountRange, setAuthorPageCountRange] = React.useState({
    min: 0,
    max: null,
  });
  const [publicationDateOrYearError, setPublicationDateOrYearError] =
    React.useState(false);
  const [publicationDateOrYearValue, setPublicationDateOrYearValue] =
    React.useState({
      startDate: null,
      endDate: null,
    });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [searchType, setSearchType] = React.useState(type || "paper");
  const [suggestionSpinner, setSuggestionSpinner] = React.useState(false);
  const [selectedAuthor, setSelectedAuthor] = React.useState({
    name: null,
    authorId: null,
    query: null,
  });
  const [suggestions, setSuggestions] = React.useState([]);
  const [suggestionsShow, setSuggestionsShow] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const [queryAuthorPapers, setQueryAuthorPapers] = React.useState("");
  const [sort, setSort] = React.useState(
    type === "author" ? "paperCount:desc" : "publicationDate:desc",
  );
  const [results, setResults] = React.useState([]);
  const [venues, setVenues] = React.useState([]);
  const [openAccessPDF, setOpenAccessPDF] = React.useState(true);
  const [minCitation, setMinCitation] = React.useState("");
  const [publicationDateOrYearFormat, setPublicationDateOrYearFormat] =
    React.useState("");
  const [customCitation, setCustomCitation] = React.useState("");
  const [fieldsOfStudy, setFieldsOfStudy] = React.useState([]);
  const [publicationTypes, setPublicationTypes] = React.useState([]);

  const [openToaster, setOpenToaster] = React.useState(false);

  const handleClickToaster = (mode = "PDF") => {
    Swal.fire({
      title:
        mode === "PDF"
          ? "Please log in to continue with PDF uploads."
          : "Please log in to unlock full research insights",
      text: ``,
      confirmButtonText: "Login",
      icon: "question",
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogin();
      } else return;
    });
  };

  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToaster(false);
  };

  let searchSuggestionRefNode = useClickOutside(() => {
    setSuggestions([]);
    setSuggestionsShow(false);
  });

  React.useEffect(() => {
    const fetchSearch = async () => {
      if (queryParam === query) return;
      setSelectedAuthor({});
      setResults([]);
      setQuery(queryParam || "");
      setVenues([]);
      setFieldsOfStudy([]);
      setPublicationTypes([]);
      setMinCitation("");
      setCustomCitation("");
      setTotalPages(1);
      setSearchType(type);
      if (type === "author") setOpenAccessPDF(false);
      if (type === "paper") setOpenAccessPDF(true);
      setSort(type === "author" ? "paperCount:desc" : "publicationDate:desc");
      setAuthorPageCountRange({
        min: 0,
        max: null,
      });
      setAuthorCitationRange({
        min: 0,
        max: null,
      });
      setPublicationDateOrYearValue({
        startDate: null,
        endDate: null,
      });
      setShowTags({
        publicationTypes: false,
        fieldsOfStudy: false,
        venues: false,
        publicationDateOrYearValue: false,
        openAccessPDF: type === "author" ? false : true,
        citationCount: false,
        authorPageCountRange: false,
        authorCitationRange: false,
      });
      setPublicationDateOrYearFormat(null);
      document.getElementById("search-page-bar").value = queryParam || "";
      if (type === "author") {
        if (!queryParam) {
          setSpinner(false);
          return;
        }
      }
      setSuggestions([]);
      try {
        setSpinner(true);
        const response = await getSearchResults(
          type,
          limit,
          queryParam || "",
          type === "author" ? "paperCount:desc" : "publicationDate:desc",
          1,
          type === "author" ? "" : "&openAccessPdf",
        );
        const data = await response.data;
        setSuggestionsShow(false);
        setResults(data.data);
        setSpinner(false);
        setTotalPages(Number(Math.round(data.total / limit)));
      } catch (err) {
        console.log(err);
        setSpinner(false);
      }
    };
    if (type && !authorId) fetchSearch();
    // eslint-disable-next-line
  }, [type, authorId, queryParam]);

  React.useEffect(() => {
    const fetch = async () => {
      setSpinner(true);
      try {
        setSort("publicationDate:desc");
        const response = await getAuthorPapersResults(
          authorId,
          20,
          "",
          "publicationDate:desc",
          1,
          "&openAccessPdf",
        );
        setOpenAccessPDF(true);
        const data = await response.data;
        setAuthorPapers(data.data || []);
        setSpinner(false);
        setSelectedAuthor({
          authorId: authorId,
          name: authorName,
          query: null,
        });
      } catch (err) {
        console.log(err);
      }
    };
    if (authorId) {
      fetch();
    }
    // eslint-disable-next-line
  }, [authorId, authorName]);

  const fetchByPageCount = async (value) => {
    try {
      setPageCount(value);
      setSpinner(true);
      const fieldsParams = returnRespectiveFilters(searchType);
      const response = await getSearchResults(
        searchType,
        limit,
        query,
        sort,
        value,
        fieldsParams,
      );
      const data = await response.data;
      setResults(data.data);
      setSpinner(false);
      setTotalPages(Number(Math.round(data.total / limit)));
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const handleSortChange = async (value) => {
    setSort(value);
    try {
      setSpinner(true);
      const fieldsParams = returnRespectiveFilters(searchType);
      const response = await getSearchResults(
        searchType,
        limit,
        query,
        value,
        pageCount,
        fieldsParams,
      );
      const data = await response.data;
      setResults(data.data);
      setSpinner(false);
      setTotalPages(Number(Math.round(data.total / limit)));
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const goToPageOne = async () => {
    try {
      if (spinner) return;
      fetchByPageCount(1);
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const nextPage = async () => {
    try {
      if (spinner) return;
      fetchByPageCount(pageCount + 1);
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const previousPage = async () => {
    try {
      if (spinner) return;
      if (pageCount === 1) return;
      window.scrollTo(0, 0);
      fetchByPageCount(pageCount - 1);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const searchResults = async (value = "", page = 1) => {
    try {
      if (value) {
        setQuery(value);
        document.getElementById("search-page-bar").value = value;
      }
      const search = value || query;
      navigate(`/search?type=${searchType}&query=${search}`);
      let tempSort = sort;
      if (type === "author" && selectedAuthor.authorId) {
        setSort("paperCount:desc");
        tempSort = "paperCount:desc";
      }
      setSelectedAuthor({
        authorId: null,
        name: null,
        query: null,
      });
      setSpinner(true);
      setPageCount(1);
      setSuggestions([]);
      setSuggestionsShow(false);
      const fieldsParams = returnRespectiveFilters(searchType);
      const response = await getSearchResults(
        searchType,
        limit,
        search,
        tempSort,
        page,
        fieldsParams,
      );
      const data = await response.data;
      setResults(data.data);
      setSpinner(false);
      setTotalPages(Number(Math.round(data.total / limit)));
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const applySearchFilters = async (value = "") => {
    try {
      if (value) {
        setQuery(value);
        document.getElementById("search-page-bar").value = value;
      }
      const search = value || query;
      setSpinner(true);
      setSuggestions([]);
      setShowTags({
        publicationTypes: publicationTypes.length > 0,
        fieldsOfStudy: fieldsOfStudy.length > 0,
        venues: venues.length > 0,
        publicationDateOrYearValue:
          publicationDateOrYearValue.startDate ||
          publicationDateOrYearValue.endDate ||
          publicationDateOrYearFormat,
        openAccessPDF: openAccessPDF,
        citationCount: minCitation.length > 0,
        authorPageCountRange:
          !authorId &&
          (authorPageCountRange.min > 0 || authorPageCountRange.max > 0),
        authorCitationRange:
          !authorId &&
          (authorCitationRange.min > 0 || authorCitationRange.max > 0),
      });
      if (!selectedAuthor.authorId) setPageCount(1);
      const fieldsParams = returnRespectiveFilters(searchType);
      if (selectedAuthor.authorId) {
        const response = await getAuthorPapersResults(
          selectedAuthor.authorId,
          limit,
          "",
          sort,
          1,
          fieldsParams,
        );
        const data = await response.data;
        setAuthorPapers(data?.data || []);
        setSpinner(false);
        return;
      } else {
        const response = await getSearchResults(
          searchType,
          limit,
          search,
          sort,
          1,
          fieldsParams,
        );
        const data = await response.data;
        setResults(data.data);
        setSpinner(false);
        setTotalPages(Number(Math.round(data.total / limit)));
        setResetTracker({
          publicationTypes: false,
          fieldsOfStudy: false,
          venues: false,
          publicationDateOrYearValue: false,
          openAccessPDF: false,
          citationCount: false,
          authorPageCountRange: false,
          authorCitationRange: false,
        });
      }
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      //@ts-ignore
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 800);
    };
  };

  const callAutoComplete = async (value) => {
    try {
      setSuggestionSpinner(true);
      setSuggestions([]);
      setSuggestionsShow(true);
      const response = await getSuggestionsResults(value);
      const data = await response.data;
      setSuggestions(data.data.slice(0, 3));
      setSuggestionSpinner(false);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.value.length >= 3 && searchType !== "author")
      callAutoComplete(e.target.value);
    setQuery(e.target.value);
  };

  // eslint-disable-next-line
  const optimizedFn = React.useCallback(debounce(handleChange), [
    callAutoComplete,
    setQuery,
  ]);

  const handleChangeAuthorPapers = (e) => {
    setQueryAuthorPapers(e.target.value);
  };

  // eslint-disable-next-line
  const optimizedFnAuthorPapers = React.useCallback(
    debounce(handleChangeAuthorPapers),
    [setQueryAuthorPapers],
  );

  const clearText = async () => {
    setQuery("");
    document.querySelector("#search-page-bar").value = "";
    if (searchType === "author") {
      return;
    }
    try {
      setSpinner(true);
      setPageCount(1);
      setSuggestions([]);
      const search = "";
      const fieldsParams = returnRespectiveFilters(searchType);
      const response = await getSearchResults(
        searchType,
        limit,
        search,
        sort,
        1,
        fieldsParams,
      );
      const data = await response.data;
      setResults(data.data);
      setSpinner(false);
      setTotalPages(Number(Math.round(data.total / limit)));
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const handleChangePublicationType = (option) => {
    if (publicationTypes.includes(option)) {
      setPublicationTypes(publicationTypes.filter((item) => item !== option));
    } else {
      setPublicationTypes([...publicationTypes, option]);
    }
  };

  const handleChangeVenues = (option) => {
    // if (venues.includes(option)) {
    //   setVenues(venues.filter((item) => item !== option));
    // } else {
    //   setVenues([...venues, option]);
    // }
    console.log(option);
    setVenues(option);
  };

  const handleChangeFieldsOfStudy = (option) => {
    if (fieldsOfStudy.includes(option)) {
      setFieldsOfStudy(fieldsOfStudy.filter((item) => item !== option));
    } else {
      setFieldsOfStudy([...fieldsOfStudy, option]);
    }
  };

  const resetPublicationTypes = () => {
    setPublicationTypes([]);
    setResetTracker({
      ...resetTracker,
      publicationTypes: true,
    });
  };

  const resetFieldsOfStudy = () => {
    setFieldsOfStudy([]);
    setResetTracker({
      ...resetTracker,
      fieldsOfStudy: true,
    });
  };

  const resetVenues = () => {
    setVenues([]);
    setResetTracker({
      ...resetTracker,
      venues: true,
    });
  };

  const resetOpenAccessPDF = () => {
    setOpenAccessPDF(false);
    setResetTracker({
      ...resetTracker,
      openAccessPDF: true,
    });
  };

  const resetPublicationDate = () => {
    setPublicationDateOrYearValue({
      startDate: null,
      endDate: null,
    });
    setPublicationDateOrYearFormat(null);
    setResetTracker({
      ...resetTracker,
      publicationDateOrYearValue: true,
    });
  };

  const resetMinimumCitation = () => {
    setMinCitation("");
    setCustomCitation("");
    setResetTracker({
      ...resetTracker,
      citationCount: true,
    });
  };

  const resetAllFilters = () => {
    resetFieldsOfStudy();
    resetMinimumCitation();
    resetPublicationDate();
    resetPublicationTypes();
    resetVenues();
    resetAuthorCitationCount();
    resetAuthorPageCount();
  };

  const resetAuthorCitationCount = () => {
    setAuthorCitationRange({
      min: 0,
      max: null,
    });
    setResetTracker({
      ...resetTracker,
      authorCitationRange: true,
    });
  };

  const resetAuthorPageCount = () => {
    setAuthorPageCountRange({
      min: 0,
      max: null,
    });
    setResetTracker({
      ...resetTracker,
      authorPageCountRange: true,
    });
  };

  const resetAllAuthorsFilters = () => {
    resetAuthorCitationCount();
    resetAuthorPageCount();
  };

  const resetFilters = async () => {
    try {
      setOpenFilter(false);
      setSpinner(true);
      setPageCount(1);
      setSuggestions([]);
      resetAllFilters();
      resetAllAuthorsFilters();
      const search = "";
      const fieldsParams = "";
      const response = await getSearchResults(
        searchType,
        limit,
        search,
        sort,
        1,
        fieldsParams,
      );
      const data = await response.data;
      setResults(data.data);
      setSpinner(false);
      setTotalPages(Number(Math.round(data.total / limit)));
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const fetchBySort = async (value) => {
    try {
      handleSortChange(value);
      setAnchorEl(false);
      setSpinner(true);
    } catch (err) {
      setSpinner(false);
      console.log(err);
    }
  };

  const handleChangeCitationRange = (value, type) => {
    setAuthorCitationRange({
      ...authorCitationRange,
      [type]: value,
    });
  };

  const handleChangePageCountRange = (value, type) => {
    setAuthorPageCountRange({
      ...authorPageCountRange,
      [type]: value,
    });
  };

  const returnRespectiveFilters = (searchType) => {
    if (searchType === "paper" || selectedAuthor.authorId) {
      let filters = `&publicationTypes=${publicationTypes.join(
        ",",
      )}&fieldsOfStudy=${fieldsOfStudy.join(",")}&venues=${venues.join(",")}`;
      if (minCitation) {
        filters =
          filters +
          `&minCitationCount=${
            minCitation === "Custom" ? customCitation : minCitation
          }`;
      }
      if (
        publicationDateOrYearFormat &&
        formatPublicationDateOrYear(
          publicationDateOrYearValue,
          publicationDateOrYearFormat,
        )
      ) {
        filters =
          filters +
          `&publicationDateOrYear=${formatPublicationDateOrYear(
            publicationDateOrYearValue,
            publicationDateOrYearFormat,
          )}`;
      }
      if (openAccessPDF) {
        filters = filters + "&openAccessPdf";
      }
      return filters;
    }
    let filters = "";
    if (authorCitationRange.min >= 0 || authorCitationRange.max >= 0) {
      filters += `&citationCount=${authorCitationRange?.min || 0}:${
        authorCitationRange?.max || 400000
      }`;
    }
    if (authorPageCountRange.min >= 0 || authorPageCountRange.max >= 0) {
      filters += `&paperCount=${authorPageCountRange?.min || 0}:${
        authorPageCountRange?.max || 400000
      }`;
    }
    return filters;
  };

  const formatPublicationDateOrYear = (
    publicationDateOrYearValue,
    publicationDateOrYearFormat,
  ) => {
    if (publicationDateOrYearFormat === "Exact Date Search")
      return formatDate(
        publicationDateOrYearValue.startDate,
        publicationDateOrYearFormat,
      );
    else if (publicationDateOrYearFormat === "Date Range") {
      if (
        publicationDateOrYearValue.startDate &&
        publicationDateOrYearValue.endDate
      ) {
        return `${formatDate(
          publicationDateOrYearValue.startDate,
          publicationDateOrYearFormat,
        )} to ${formatDate(
          publicationDateOrYearValue.endDate,
          publicationDateOrYearFormat,
        )}`;
      }
      return `${
        publicationDateOrYearValue.startDate
          ? `${formatDate(
              publicationDateOrYearValue.startDate,
              publicationDateOrYearFormat,
            )} and above`
          : ""
      }:${
        publicationDateOrYearValue.endDate
          ? `Before ${formatDate(
              publicationDateOrYearValue.endDate,
              publicationDateOrYearFormat,
            )}`
          : ""
      }`;
    } else if (publicationDateOrYearFormat === "Year Range") {
      if (
        publicationDateOrYearValue.startDate &&
        publicationDateOrYearValue.endDate
      ) {
        return `${formatDate(
          publicationDateOrYearValue.startDate,
          publicationDateOrYearFormat,
        )} to ${formatDate(
          publicationDateOrYearValue.endDate,
          publicationDateOrYearFormat,
        )}`;
      } else
        return `${
          publicationDateOrYearValue.startDate
            ? `${formatDate(
                publicationDateOrYearValue.startDate,
                publicationDateOrYearFormat,
              )} and above`
            : `Before ${formatDate(
                publicationDateOrYearValue.endDate,
                publicationDateOrYearFormat,
              )}`
        }`;
    }
  };

  const changeSearchType = (value) => {
    if (value === searchType) return;
    navigate(`/search?type=${value}`);
  };

  const exploreAuthorPages = async (author) => {
    navigate(
      `/search?type=author&authorId=${author.authorId}&authorName=${author.name}`,
    );
  };

  const fetchAuthorPapers = async (selectedAuthorData, authorPageCount = 1) => {
    try {
      const fieldsParams = returnRespectiveFilters(searchType);
      setSort("publicationDate:desc");
      const response = await getAuthorPapersResults(
        selectedAuthorData.authorId,
        20,
        queryAuthorPapers,
        "publicationDate:desc",
        authorPageCount,
        fieldsParams,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetch = async () => {
      setSpinner(true);
      window.scrollTo(0, 0);
      const data = await fetchAuthorPapers(selectedAuthor, 1);
      setShowAuthorPapersShowMore(data.total >= authorPageCount * limit);
      setAuthorPapers(data.data || []);
      setSpinner(false);
    };
    if (selectedAuthor.authorId) {
      fetch();
    }
    // eslint-disable-next-line
  }, [sort, queryAuthorPapers]);

  React.useEffect(() => {
    const fetch = async () => {
      setShowAuthorPapersShowMoreLoader(true);
      const data = await fetchAuthorPapers(selectedAuthor, authorPageCount);
      setShowAuthorPapersShowMore(data.total >= authorPageCount * limit);
      setAuthorPapers([...authorPapers, ...data.data] || []);
      setShowAuthorPapersShowMoreLoader(false);
    };
    if (selectedAuthor.authorId) {
      fetch();
    }
    // eslint-disable-next-line
  }, [authorPageCount]);

  React.useEffect(() => {
    if (
      resetTracker.publicationDateOrYearValue ||
      resetTracker.fieldsOfStudy ||
      resetTracker.openAccessPDF ||
      resetTracker.publicationTypes ||
      resetTracker.venues ||
      resetTracker.citationCount ||
      resetTracker.authorCitationRange ||
      resetTracker.authorPageCountRange
    ) {
      applySearchFilters();
    }
    // eslint-disable-next-line
  }, [
    resetTracker.publicationTypes,
    resetTracker.fieldsOfStudy,
    resetTracker.venues,
    resetTracker.publicationDateOrYearValue,
    resetTracker.openAccessPDF,
    resetTracker.citationCount,
    resetTracker.authorCitationRange,
    resetTracker.authorPageCountRange,
  ]);

  React.useEffect(() => {
    const uploadPDFHandler = async () => {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append("file", uploadPDF);
        const response = await uploadPDFService(formData);
        if (response.data.paperId) {
          const { paperId, sementicScholarPaperId, publicUrl } = response.data;
          setUploading(false);
          navigate(
            `/understanding-page?paperId=${paperId}&sementicScholarPaperId=${sementicScholarPaperId}&publicUrl=${publicUrl}`,
          );
        }
      } catch (err) {
        setUploading(false);
        if (err.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonText: "Increase your limit",
            text: err?.response?.data?.error || "Something went wrong!.",
          }).then((result) => {
            if (result.isConfirmed) {
              document.getElementById("upgrade-btn-id").click();
            } else return;
          });
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.error || "Something went wrong!.",
        });
      }
    };
    if (uploadPDF) {
      uploadPDFHandler();
    }
    // eslint-disable-next-line
  }, [uploadPDF]);

  const handleLogin = async () => {
    const currentUrl = window.location.href;
    window.location.href = `${
      process.env.REACT_APP_SERVER_API
    }/auth/google?redirect=${encodeURIComponent(currentUrl)}`;
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseToaster}
      >
        <Icon icon="ic:baseline-close" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="research-page-main">
      <Helmet>
        <title>Search | Papertalk.io</title>
        <meta
          name="Search | Papertalk.io"
          content="Papertalk.io is a platform designed to harness the power of research for everyone. Whether you're a student, researcher, or professional, our advanced filters help you quickly find research papers that matter to you. Our AI-powered explanations break down complex papers into understandable insights. Stuck on a tricky part? Just ask our chatbot research assistant. We go further by showing you how to apply these insights practically, making research not just accessible, but actionable. Join us to make research an asset in your work."
        />
      </Helmet>
      {uploading && <SimpleBackdrop open={uploading} />}
      <TransitionsModal
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        searchType={searchType}
        fetchAPI={() => {
          if (
            searchType === "author" &&
            (authorCitationRange.min > (authorCitationRange?.max || 400000) ||
              authorPageCountRange.min > (authorPageCountRange?.max || 400000))
          ) {
            return;
          }
          applySearchFilters();
          setOpenFilter(false);
        }}
      >
        {(publicationTypes.length > 0 ||
          venues.length > 0 ||
          fieldsOfStudy.length > 0 ||
          minCitation > 0 ||
          publicationDateOrYearFormat) && (
          <div className="clear-all-filters" onClick={resetFilters}>
            Clear All
          </div>
        )}
        {searchType === "paper" || selectedAuthor?.authorId ? (
          <div>
            <div className="modal-filter-div">
              <div className="modal-filter-div-title-flex">
                <span className="modal-filter-div-title">Open Access PDF</span>
                <FormControlLabel
                  control={
                    <Switch
                      style={{ color: "#41169c" }}
                      color={"secondary"}
                      checked={openAccessPDF}
                      onChange={(e) => setOpenAccessPDF(e.target.checked)}
                    />
                  }
                  label=""
                />
              </div>
            </div>
            <PublicationTypes
              handleChangePublicationType={handleChangePublicationType}
              publicationTypes={publicationTypes}
              publicationTypesArr={publicationTypesArr}
            />
            <VenuesFilter
              handleChangeVenues={handleChangeVenues}
              journals={journals}
              venues={venues}
            />
            <FieldsOfStudy
              academicDisciplines={academicDisciplines}
              fieldsOfStudy={fieldsOfStudy}
              handleChangeFieldsOfStudy={handleChangeFieldsOfStudy}
            />
            <MinimumCitationCheckbox
              minCitation={minCitation}
              minCitationArr={minCitationArr}
              setCustomCitation={setCustomCitation}
              setMinCitation={setMinCitation}
              customCitation={customCitation}
            />
            <PublicationYear
              publicationDateOrYearError={publicationDateOrYearError}
              publicationDateOrYearFormat={publicationDateOrYearFormat}
              publicationDateOrYearValue={publicationDateOrYearValue}
              publicationDateAndYearArr={publicationDateAndYearArr}
              setPublicationDateOrYearError={setPublicationDateOrYearError}
              setPublicationDateOrYearFormat={setPublicationDateOrYearFormat}
              setPublicationDateOrYearValue={setPublicationDateOrYearValue}
            />
          </div>
        ) : (
          <div>
            <AuthorCitationRange
              authorCitationRange={authorCitationRange}
              handleChangeCitationRange={handleChangeCitationRange}
            />
            <AuthorPageCountRange
              authorPageCountRange={authorPageCountRange}
              handleChangePageCountRange={handleChangePageCountRange}
            />
          </div>
        )}
      </TransitionsModal>
      <div className="research-page-main-header">
        <div className="research-page-main-header-div">
          <div className="research-page-main-header-searchbar-combined">
            <form
              className="research-page-main-header-searchbar"
              onSubmit={(e) => {
                e.preventDefault();
                if(spinner) return;
                searchResults(e.target[0].value, 1);
              }}
            >
              <Icon
                icon="bi:search"
                color="#41169c"
                style={{
                  fontSize: "1.5rem",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
              <input
                placeholder={`Search ${searchType}s`}
                id="search-page-bar"
                onChange={optimizedFn}
                className="research-page-main-header-searchbar-input"
              />
              {query.length !== 0 && (
                <button
                  type="button"
                  onClick={clearText}
                  className="clear-text-ic"
                >
                  <Icon icon="charm:cross" fontSize={"25px"} color="#ccc" />
                </button>
              )}
              <button
                type="submit"
                className="research-page-main-header-searchbar-btn"
              >
                Search
              </button>
            </form>
            <div
              className="research-page-main-header-searchbar-upload-pdf-btn"
              onClick={() => {
                if (window.localStorage.getItem("email"))
                  document.getElementById("upload-pdf-input").click();
                else handleClickToaster("PDF");
              }}
            >
              <input
                type={"file"}
                id="upload-pdf-input"
                style={{ display: "none" }}
                onChange={(event) => {
                  setUploadPDF(event.currentTarget.files[0]);
                }}
              />
              <Icon
                icon="ep:upload-filled"
                color="#41169c"
                style={{ fontSize: "22px" }}
              />
              Upload PDF
            </div>
          </div>
          <div className="research-page-main-header-filter-flex">
            <div
              className={
                searchType === "paper"
                  ? "filter-search-bar-btn-active"
                  : "filter-search-bar-btn"
              }
              style={{ cursor: spinner ? 'not-allowed' : 'pointer' }}
              onClick={() => {
                if(spinner) return;
                changeSearchType("paper");
              }}
            >
              BY PAPER NAME
            </div>
            <div
              className={
                searchType === "author"
                  ? "filter-search-bar-btn-active"
                  : "filter-search-bar-btn"
              }
              style={{ cursor: spinner ? 'not-allowed' : 'pointer' }}
              onClick={() => {
                if (spinner) return;

                changeSearchType("author");
              }}
            >
              BY AUTHOR NAME
            </div>
          </div>
          {suggestionSpinner && suggestionsShow && (
            <div className="suggestion-listing">
              <div className="suggestion-list-li">
                <p>loading...</p>
              </div>
            </div>
          )}
          {
            <div className="suggestion-listing" ref={searchSuggestionRefNode}>
              {suggestionsShow && (
                <>
                  {query && (
                    <div
                      className="suggestion-list-li"
                      onClick={() => searchResults(query, 1)}
                    >
                      <p>Search for {`"${query}"` || ""}</p>
                    </div>
                  )}
                  {suggestions?.map((suggest) => (
                    <div
                      key={suggest.title}
                      className="suggestion-list-li"
                      onClick={() => searchResults(suggest.title, 1)}
                    >
                      <p>{suggest?.title || ""}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          }
        </div>
      </div>
      <div className="research-page-body">
        <div className="research-sort-filter-flex">
          <div className="research-filter-tags-flex-div">
            <div>
              <div
                aria-describedby={id}
                className="sort-filter-btn-active"
                onClick={handleClick}
              >
                <Icon
                  icon="mi:filter"
                  color="white"
                  style={{ fontSize: "18px" }}
                />{" "}
                {(searchType === "author" && !selectedAuthor.authorId
                  ? sortOptionsAuthor
                  : sortOptions
                )?.find((item) => item?.sorts.find((obj) => obj.value === sort))
                  ?.type
                  ? `${
                      (searchType === "author" && !selectedAuthor.authorId
                        ? sortOptionsAuthor
                        : sortOptions
                      )?.find((item) =>
                        item?.sorts.find((obj) => obj.value === sort),
                      )?.type
                    } : ${
                      (searchType === "author" && !selectedAuthor.authorId
                        ? sortOptionsAuthor
                        : sortOptions
                      )
                        ?.find((item) =>
                          item?.sorts.find((obj) => obj.value === sort),
                        )
                        ?.sorts.find((obj) => obj.value === sort)?.label
                    }`
                  : "SORT"}
              </div>
              <Popover
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                style={{
                  borderRadius: "15px",
                  marginTop: "5px",
                }}
              >
                <Box
                  style={{
                    maxHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {(searchType === "author" && !selectedAuthor.authorId
                    ? sortOptionsAuthor
                    : sortOptions
                  ).map((item) => (
                    <Typography
                      key={item.type}
                      style={{
                        borderBottom: "0.5px solid #ccc",
                      }}
                      sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          columnGap: "5px",
                        }}
                      >
                        <span style={{ textAlign: "right", minWidth: "50%" }}>
                          {item.type} :
                        </span>
                        {item?.sorts?.map((item) => (
                          <div
                            key={item.value}
                            style={{
                              backgroundColor:
                                sort === item.value ? "#925ff5" : "#ccc",
                              color: "#fff",
                              borderRadius: "10px",
                              width: "50px",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => fetchBySort(item.value)}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </Typography>
                  ))}
                </Box>
              </Popover>
            </div>
            {showTags.publicationTypes && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  appliedFilter={publicationTypes}
                  callFilterAPI={applySearchFilters}
                  title={"Publication Types"}
                >
                  <PublicationTypes
                    handleChangePublicationType={handleChangePublicationType}
                    publicationTypes={publicationTypes}
                    publicationTypesArr={publicationTypesArr}
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetPublicationTypes} />
              </div>
            )}
            {showTags.fieldsOfStudy && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  appliedFilter={fieldsOfStudy}
                  callFilterAPI={applySearchFilters}
                  title={"Fields of Study"}
                >
                  <FieldsOfStudy
                    academicDisciplines={academicDisciplines}
                    fieldsOfStudy={fieldsOfStudy}
                    handleChangeFieldsOfStudy={handleChangeFieldsOfStudy}
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetFieldsOfStudy} />
              </div>
            )}
            {showTags.citationCount && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  callFilterAPI={applySearchFilters}
                  title={`Minimum Citation : 
                ${
                  minCitation === "Custom"
                    ? customCitation
                    : formatNumber(minCitation)
                }`}
                >
                  <MinimumCitationCheckbox
                    minCitation={minCitation}
                    minCitationArr={minCitationArr}
                    setCustomCitation={setCustomCitation}
                    setMinCitation={setMinCitation}
                    customCitation={customCitation}
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetMinimumCitation} />
              </div>
            )}
            {showTags.publicationDateOrYearValue && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  callFilterAPI={applySearchFilters}
                  title={`Publication Date :
                  ${formatPublicationDateOrYear(
                    publicationDateOrYearValue,
                    publicationDateOrYearFormat,
                  )}
                  `}
                >
                  <PublicationYear
                    publicationDateOrYearError={publicationDateOrYearError}
                    publicationDateOrYearFormat={publicationDateOrYearFormat}
                    publicationDateOrYearValue={publicationDateOrYearValue}
                    publicationDateAndYearArr={publicationDateAndYearArr}
                    setPublicationDateOrYearError={
                      setPublicationDateOrYearError
                    }
                    setPublicationDateOrYearFormat={
                      setPublicationDateOrYearFormat
                    }
                    setPublicationDateOrYearValue={
                      setPublicationDateOrYearValue
                    }
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetPublicationDate} />
              </div>
            )}
            {showTags.venues && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  appliedFilter={venues}
                  callFilterAPI={applySearchFilters}
                  title={"Venues"}
                >
                  <VenuesFilter
                    handleChangeVenues={handleChangeVenues}
                    journals={journals}
                    venues={venues}
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetVenues} />
              </div>
            )}

            {(type === "paper" || authorId) && openAccessPDF && (
              <div className="selected-search-filter-btn">
                <FilterTag cursor="initial" title="Open Access PDF" />
                <RemoveBtn onClick={resetOpenAccessPDF} />
              </div>
            )}

            {!authorId && showTags.authorCitationRange && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  callFilterAPI={applySearchFilters}
                  title={`Minimum Citation : ${formatNumber(
                    authorCitationRange.min,
                  )} ${" "}
                ${
                  authorCitationRange?.max
                    ? ` - ${formatNumber(authorCitationRange.max)}`
                    : ""
                }`}
                >
                  <AuthorCitationRange
                    authorCitationRange={authorCitationRange}
                    handleChangeCitationRange={handleChangeCitationRange}
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetAuthorCitationCount} />
              </div>
            )}
            {!authorId && showTags.authorPageCountRange && (
              <div className="selected-search-filter-btn">
                <FilterTagWithModal
                  callFilterAPI={applySearchFilters}
                  title={`Minimum Page Count : ${formatNumber(
                    authorPageCountRange.min,
                  )}${" "}
                ${
                  authorPageCountRange?.max
                    ? ` - ${formatNumber(authorPageCountRange.max)}`
                    : ""
                }`}
                >
                  <AuthorPageCountRange
                    authorPageCountRange={authorPageCountRange}
                    handleChangePageCountRange={handleChangePageCountRange}
                  />
                </FilterTagWithModal>
                <RemoveBtn onClick={resetAuthorPageCount} />
              </div>
            )}
          </div>
          <div
            className="sort-filter-btn-active"
            onClick={() => setOpenFilter(true)}
          >
            <Icon
              icon="icons8:filter"
              color="white"
              style={{ fontSize: "20px" }}
            />{" "}
            ALL FILTER
          </div>
        </div>
        {!spinner && !selectedAuthor.authorId && (
          <div
            className={
              searchType === "paper"
                ? "researches-flex-listing-body"
                : "researches-grid-listing-body"
            }
          >
            {results.map((searched, index) => {
              if (searchType === "paper")
                return (
                  <SearchCard
                    handleClickToaster={handleClickToaster}
                    paperId={searched.paperId}
                    publicationTypes={searched.publicationTypes}
                    key={searched.paperId}
                    fieldsOfStudy={searched.fieldsOfStudy}
                    citationCount={searched.citationCount}
                    venue={searched.venue}
                    author={searched.authors}
                    title={searched.title}
                    date={searched.publicationDate}
                    description={searched?.abstract || ""}
                    extra={searched?.extra || ""}
                  />
                );
              if (searchType === "author" && !selectedAuthor.authorId)
                return (
                  <AuthorCard
                    key={searched.authorId}
                    author={searched}
                    exploreAuthorPages={exploreAuthorPages}
                    title={searched.name}
                    index={index}
                    authorId={searched.authorId}
                    citationCount={searched.citationCount}
                    paperCount={searched.paperCount}
                  />
                );
              return null;
            })}
          </div>
        )}
        {selectedAuthor.authorId && (
          <div>
            <div className="go-back-to-authors-div">
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  columnGap: "15px",
                  cursor:
                    window.history.state.idx === 0 ? "initial" : "pointer",
                }}
                onClick={() => {
                  if (window.history.state.idx === 0) return;
                  setSelectedAuthor({
                    authorId: null,
                    name: null,
                    query: null,
                  });
                  resetAllFilters();
                  setQueryAuthorPapers("");
                  setSort("paperCount:desc");
                  navigate(-1);
                }}
              >
                {window.history.state.idx !== 0 && (
                  <Icon
                    icon="ic:outline-arrow-back"
                    color="#41169c"
                    style={{ fontSize: "22px" }}
                  />
                )}
                <span className="author-paper-results">
                  Showing results from Papers of "{selectedAuthor.name}"
                </span>
              </div>
              <div className="author-papers-search-results-div">
                <div className="author-papers-searchbar-div">
                  <Icon
                    icon="tabler:search"
                    color="#41169c"
                    style={{ fontSize: "20px" }}
                  />
                  <input
                    className="author-papers-searchbar-input"
                    onChange={optimizedFnAuthorPapers}
                    placeholder="Search papers"
                  />
                </div>
              </div>
            </div>
            {!spinner && (
              <>
                <div
                  className="researches-flex-listing-body"
                  style={{ marginTop: "30px" }}
                >
                  {authorPapers.map((searched) => (
                    <SearchCard
                      handleClickToaster={handleClickToaster}
                      paperId={searched.paperId}
                      publicationTypes={searched.publicationTypes}
                      key={searched.paperId}
                      citationCount={searched.citationCount}
                      venue={searched.venue}
                      author={searched.authors}
                      fieldsOfStudy={searched.fieldsOfStudy}
                      title={searched.title}
                      date={searched.publicationDate}
                      description={searched?.abstract || ""}
                      extra={searched?.extra || ""}
                    />
                  ))}
                </div>
                {showAuthorPapersShowMore && authorPapers.length !== 0 && (
                  <div className="show-more-div">
                    {authorPapersShowMoreLoader ? (
                      <CircularProgress />
                    ) : (
                      <span
                        onClick={() => setAuthorPageCount(authorPageCount + 1)}
                      >
                        Show more
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!spinner &&
          results.length === 0 &&
          type !== "author" &&
          !selectedAuthor.authorId && (
            <div className="no-data-found-div">
              <p>No Data Found.</p>
            </div>
          )}
        {type === "author" &&
          (authorId ? authorPapers.length === 0 : results.length === 0) &&
          !spinner && (
            <div className="no-data-found-div">
              {query ? (
                <p>No Data Found.</p>
              ) : (
                <p>Type an author's name to begin your search.</p>
              )}
            </div>
          )}
        {spinner && (
          <div className="researches-flex-listing-body">
            <Loader />
          </div>
        )}
      </div>
      {!selectedAuthor.authorId && (
        <Paginator
          loading={spinner}
          previousPage={previousPage}
          totalPages={totalPages}
          pageCount={pageCount}
          goToPageOne={goToPageOne}
          nextPage={nextPage}
        />
      )}
      <Snackbar
        open={openToaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        message="You must login first."
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </div>
  );
};

export default SearchPage;
