import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import "../../assets/css/SearchCard.css";
import { shortenName } from "../../utils/textHelpers";
import AudioPlayer from "../AudioPlayer";
import SimpleBackdrop from "../Backdrop";

const LibraryCard = ({
  title,
  author,
  description,
  paperId,
  sementicScholarPaperId,
  audioUrl,
  publicUrl,
}) => {
  const [abbreviatedDescription, setAbbreviatedDescription] = React.useState(
    description.length > 50
      ? description.split(".").slice(0, 1).join(". ") + "..."
      : description,
  );

  const [expand, setExpand] = React.useState(false);
  const navigate = useNavigate();
  const [spinner, setSpinner] = React.useState(false);

  const uploadPDFHandler = async () => {
    try {
      navigate(
        `/understanding-page?paperId=${
          paperId || undefined
        }&sementicScholarPaperId=${
          sementicScholarPaperId || undefined
        }&publicUrl=${publicUrl || undefined}`,
      );
      // }
    } catch (err) {
      setSpinner(false);
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (expand) setAbbreviatedDescription(description);
    else
      setAbbreviatedDescription(
        description.length > 50
          ? description.split(".").slice(0, 1).join(". ") + "..."
          : description,
      );
    // eslint-disable-next-line
  }, [expand]);

  return (
    <div className="feature-card">
      {spinner && <SimpleBackdrop open={spinner} />}
      <div className="feature-card-top-border" />
      <div className="feature-card-header">
        <div className="purple-head-left-border" />
        <span className="feature-card-title">{title}</span>
      </div>
      <div className="feature-card-body-tags-date-flex">
        <div className="feature-card-body-tags-flex">
          {author?.slice(0, 3).map((item, index) => (
            <div
              style={{ cursor: !item?.authorId ? "initial" : "pointer" }}
              className="feature-card-body-tag"
              key={`${index}_${item.name}`}
              onClick={() => {
                if (!item?.authorId) {
                  return;
                }
                window
                  .open(
                    `/search?type=author&authorId=${item.authorId}&authorName=${item.name}`,
                  )
                  .focus();
              }}
            >
              <Icon icon="bi:person-fill" color="#3E3F66" />
              <Tooltip title={item?.name}>
                <span>{shortenName(item?.name || item.trim(), 9)}</span>
              </Tooltip>
              {item?.authorId && (
                <Icon icon="gridicons:external" color="#3E3F66" />
              )}
            </div>
          ))}
        </div>
        <div
          className="sort-filter-btn-active"
          style={{ minWidth: "166px", maxHeight: "20px" }}
          onClick={() => uploadPDFHandler(paperId)}
        >
          Start Understanding
          <Icon icon="ph:arrow-right-bold" color="white" />
        </div>
      </div>
      <div className="feature-card-divider" />
      <div className="features-desc">
        <span>
          {abbreviatedDescription}{" "}
          {description.length !== 0 && (
            <span
              className="feature-card-expand"
              onClick={() => setExpand(!expand)}
            >
              {expand ? "Show less" : "Show more"}
            </span>
          )}
        </span>
      </div>
      {/* <div className="research-card-footer">
        
      </div> */}
      <div className="library-card-audio-player">
        <AudioPlayer mode={"dark"} src={audioUrl} />
      </div>

      <div className="feature-card-bottom-border" />
    </div>
  );
};

export default LibraryCard;
