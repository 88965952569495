import Home from "./pages/Home";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import SearchPage from "./pages/Search";
import MyLibrary from "./pages/MyLibrary";
import UnderstandingPage from "./pages/UnderstandingPage";
// import TnC from "./pages/TnC";
import Privacy from "./pages/PrivacyPolicy";

function App() {
  const authenticated = window.localStorage.getItem("email");
  return (
    <div className="App">
      <Navbar />
      <div className="global-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchPage />} />
          <Route
            path="/my-library"
            element={authenticated ? <MyLibrary /> : <Navigate to={"/"} />}
          />
          <Route
            path="/understanding-page"
            element={
              authenticated ? <UnderstandingPage /> : <Navigate to={"/"} />
            }
          />
          {/* <Route path="/terms-and-condition" element={<TnC />} /> */}
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
