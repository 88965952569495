import React from "react";
// import logo from "../assets/images/Logo.webp";
import "../assets/css/Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import rocket from "../assets/images/rocket.webp";
import { Icon } from "@iconify/react";
import {
  // Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { logoutAxios, profileDetails } from "../services/Auth.services";
// import UpgradeModal from "./UpgradeModal";
import RequestFeedbackForm from "./UpgradeModal/RequestFeedbackForm";

const useClickOutside = (handler) => {
  let domNode = React.useRef(null);

  React.useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Navbar = () => {
  const email = window.localStorage.getItem("email");
  const name = window.localStorage.getItem("name");
  const profilePicUrl = window.localStorage.getItem("profilePicUrl");

  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = React.useState(false);
  const [openUpgrade, setOpenUpgrade] = React.useState(false);

  let searchRefNode = useClickOutside(() => {
    if (!openSideBar) {
      return;
    }
    setOpenSideBar(false);
    document.querySelector("body").style.overflow = "scroll";
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSideBarFunc = () => {
    if (openSideBar) return;
    setOpenSideBar(true);
    document.querySelector("body").style.overflow = openSideBar
      ? "scroll"
      : "hidden";
  };

  const handleClickSection = (e, value) => {
    e.preventDefault();
    const element = document.getElementById(value);
    if (openSideBar) {
      setOpenSideBar(false);
      document.querySelector("body").style.overflow = "scroll";
    }

    if (window.location.pathname === "/") {
      if (element) {
        const scrollOffset = 90;

        setTimeout(() => {
          window.scrollTo({
            top: element.offsetTop - scrollOffset,
            behavior: "smooth",
          });
        }, 100);
      }
    } else {
      navigate(`/?id=${value}`);
    }
  };

  const closeSideBar = () => {
    document.querySelector("body").style.overflow = "scroll";
    setOpenSideBar(false);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      ["products", "teams", "pricing", "faq"].forEach((link) => {
        const section = document.getElementById(link);

        if (section) {
          const scrollOffset = 140; // Adjust this value based on your requirement

          if (
            window.scrollY > section.offsetTop - scrollOffset &&
            window.scrollY < section.offsetTop + section.offsetHeight
          ) {
            document.querySelector(`#${link}_li`).classList.add("active_li");
          } else {
            document.querySelector(`#${link}_li`).classList.remove("active_li");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogin = async () => {
    const currentUrl = window.location.href;
    window.location.href = `${
      process.env.REACT_APP_SERVER_API
    }/auth/google?redirect=${encodeURIComponent(currentUrl)}`;
  };

  React.useEffect(() => {
    if (email === null) handleProfile();
    // eslint-disable-next-line
  }, []);

  const handleProfile = async () => {
    try {
      const response = await profileDetails();
      if (response.data) {
        const userData = await response.data;
        window.localStorage.setItem("email", userData.email);
        window.localStorage.setItem("name", userData.name);
        window.localStorage.setItem("profilePicUrl", userData.profilePicUrl);
        window.localStorage.setItem("uploadLimit", userData.uploadLimit);
        window.location.reload();
      }
      // Update the local state
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleLogout = async () => {
    try {
      // Call the logout endpoint on your backend
      await logoutAxios();
      // Update the local state
      window.localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <nav className="navbar-main-div">
      <div className="navbar-div">
        <NavLink to="/" onClick={(e) => handleClickSection(e, "#")}>
          <img
            src={
              "https://res.cloudinary.com/papertalk-io/image/upload/v1704566958/eqzbspnj8a9ufkcyobpt.webp"
            }
            className="navbar-logo"
            alt="papertalk.io"
          />
        </NavLink>
        <ul className="navbar-main-ul">
          <li>
            <NavLink
              className={"no-text-decoration"}
              to="/"
              id="products_li"
              onClick={(e) => handleClickSection(e, "products")}
            >
              Product
            </NavLink>
          </li>
          <li>
            <NavLink
              className={"no-text-decoration"}
              to="/"
              id="teams_li"
              onClick={(e) => handleClickSection(e, "teams")}
            >
              Team
            </NavLink>
          </li>
          {/* <li>
          <NavLink
            className={"no-text-decoration"}
            to="/"
            id="pricing_li"
            onClick={(e) => handleClickSection(e, "pricing")}
          >
            Pricing
          </NavLink>
        </li> */}
          <li>
            <NavLink
              className={"no-text-decoration"}
              to="/"
              id="faq_li"
              onClick={(e) => handleClickSection(e, "faq")}
            >
              FAQ
            </NavLink>
          </li>
        </ul>
        <div className="navbar-main-buttons-div">
          {email ? (
            <button
              className="navbar-main-button-purple"
              onClick={() => navigate("/my-library")}
            >
              My Library
            </button>
          ) : (
            <button onClick={handleLogin} className="navbar-main-button-white">
              Login
            </button>
          )}
          {email && (
            <button
              onClick={() => setOpenUpgrade(true)}
              className="navbar-main-button-white"
              id="upgrade-btn-id"
            >
              Upgrade
              <img
                style={{ width: "25px", height: "25px" }}
                alt="rocket"
                src={rocket}
              />
            </button>
          )}
          {email && (
            <div className="google-auth-div">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <img alt={name.slice(0,1)} src={profilePicUrl} referrerpolicy="no-referrer" className="avatar-img" />
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 99999,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Icon icon="mdi:logout" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
        <div className="mobile-hamburger">
          {openSideBar ? (
            <div onClick={openSideBarFunc}>
              <Icon
                icon="maki:cross"
                color="#41169c"
                style={{ fontSize: "20px" }}
              />
            </div>
          ) : (
            <div onClick={openSideBarFunc}>
              <Icon
                icon="ci:hamburger-md"
                color="#41169c"
                style={{ fontSize: "22px" }}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="sidebar-floating-backdrop"
        style={{
          right: openSideBar ? "0px" : "-5000px",
          backgroundColor: openSideBar ? "#0d0d0e4c" : "transparent",
        }}
      >
        <div className="sidebar-floating" ref={searchRefNode}>
          <div className="sidebar-floating-content">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "30px",
              }}
            >
              {name ? (
                <>
                  {" "}
                  <img alt={name.slice(0,1)} src={profilePicUrl} referrerpolicy="no-referrer" className="avatar-img" />
                  <span className="sidebar-floating-content-title">{name}</span>
                </>
              ) : (
                <button onClick={handleLogin} className="bordered-button-login">
                  Log in
                </button>
              )}
            </div>
            <span
              className="sidebar-floating-content-link"
              onClick={(e) => handleClickSection(e, "products")}
            >
              Products
            </span>
            <span
              className="sidebar-floating-content-link"
              onClick={(e) => handleClickSection(e, "teams")}
            >
              Team
            </span>
            {/* <span
              className="sidebar-floating-content-link"
              onClick={(e) => handleClickSection(e, "pricing")}
            >
              Pricing
            </span> */}
            <span
              className="sidebar-floating-content-link"
              onClick={(e) => handleClickSection(e, "faq")}
            >
              FAQ
            </span>
            {email && (
              <button
                className="white-button-library"
                onClick={() => {
                  navigate("/my-library");
                  closeSideBar();
                }}
              >
                My Library
              </button>
            )}
            {email && (
              <button
                onClick={() => setOpenUpgrade(true)}
                className="white-button-library"
              >
                Upgrade
                <img
                  style={{ width: "25px", height: "25px" }}
                  alt="rocket"
                  src={rocket}
                />
              </button>
            )}
            {email && (
              <button onClick={handleLogout} className="bordered-button-login">
                Log out
              </button>
            )}
          </div>
        </div>
      </div>
      <RequestFeedbackForm
        open={openUpgrade}
        handleClose={() => setOpenUpgrade(false)}
      />
    </nav>
  );
};

export default Navbar;
