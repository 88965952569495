import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Icon } from "@iconify/react";
const style = {
  position: "absolute",
  top: window.innerWidth > 500 ? "55%" : "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth - 20,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  outline: "none",
  // p: 4,
  // overflowY: 'scroll',
  borderRadius: "13px",
  maxWidth: "570px",
  maxHeight: "80vh",
};

export default function TransitionsModal({
  children,
  open,
  handleClose,
  searchType,
  fetchAPI,
}) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal-header">
              <div className="modal-header-title">
                <span>Filters</span>
                {/* <div className="modal-header-title-tag">
                  By {firstLetterCapital(searchType)} Name
                </div> */}
              </div>
              <div className="modal_close" onClick={() => handleClose()}>
                <Icon icon="maki:cross" />
              </div>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              <div
                className="modal-footer-btn"
                onClick={() => {
                  fetchAPI();
                }}
              >
                Show results
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
