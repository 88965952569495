export const publicationTypesArr = [
  { title: "Review", value: "Review" },
  { title: "Journal Article", value: "Journal Article" },
  { title: "Case Report", value: "Case Report" },
  { title: "Clinical Trial", value: "Clinical Trial" },
  { title: "Dataset", value: "Dataset" },
  { title: "Editorial", value: "Editorial" },
  { title: "Letters And Comments", value: "Letters And Comments" },
  { title: "Meta Analysis", value: "Meta Analysis" },
  { title: "News", value: "News" },
  { title: "Study", value: "Study" },
  { title: "Book", value: "Book" },
  { title: "Book Section", value: "BookSection" },
];

// export const journals = [
//   { title: "Nature", value: "Nature" },
//   {
//     title: "New England Journal of Medicine",
//     value: "NewEngl and Journal Of Medicine",
//   },
//   { title: "Radiology", value: "Radiology" },
//   { title: "N. Engl. J. Med.", value: "NEJM" },
// ];

export const journals = [
  { title: "Nature", label: "Nature" },
  { title: "Science", label: "Science" },
  { title: "The Lancet", label: "Lancet" },
  { title: "British Medical Journal", label: "Br. Med. J." },
  { title: "Journal of the American Medical Association", label: "JAMA" },
  {
    title:
      "Proceedings of the National Academy of Sciences of the United States of America",
    label: "Proc. Natl. Acad. Sci. U. S. A.",
  },
  { title: "Cell", label: "Cell" },
  { title: "New England Journal of Medicine", label: "N. Engl. J. Med." },
  { title: "Journal of Biological Chemistry", label: "J. Biol. Chem." },
  { title: "Astrophysical Journal", label: "Astrophys. J." },
  { title: "Physical Review Letters", label: "Phys. Rev. Lett." },
  { title: "Annual Review of Psychology", label: "Annu. Rev. Psychol." },
  { title: "Journal of Political Economy", label: "J. Polit. Econ." },
  { title: "American Economic Review", label: "Am. Econ. Rev." },
  { title: "Journal of Finance", label: "J. Finance" },
  // {
  //   title: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
  //   label: "IEEE Trans. Pattern Anal. Mach. Intell.",
  // },
  { title: "Chemical Reviews", label: "Chem. Rev." },
  { title: "Journal of Fluid Mechanics", label: "J. Fluid Mech." },
  { title: "Radiology", label: "Radiology" },
  { title: "Modern Language Review", label: "Mod. Lang. Rev." },
  {
    title: "IEEE Transactions on Mobile Computing",
    label: "IEEE Trans. Mob. Comput.",
  },
  { title: "Wireless Networks", label: "Wirel. Netw." },
  { title: "Mobile Networks and Applications", label: "Mob. Netw. Appl." },
  { title: "IEEE Wireless Communications", label: "IEEE Wirel. Commun." },
  {
    title: "Journal of Communications and Networks",
    label: "J. Commun. Netw.",
  },
  {
    title: "Journal of Machine Learning Research",
    label: "J. Mach. Learn. Res.",
  },
  {
    title: "Neural Information Processing Systems (NeurIPS) - Conference",
    label: "NeurIPS",
  },
  { title: "International Conference on Machine Learning", label: "ICML" },
  { title: "Machine Learning", label: "Mach. Learn." },
  {
    title: "ACM Transactions on Computer Systems",
    label: "ACM Trans. Comput. Syst.",
  },
  { title: "IEEE Transactions on Computers", label: "IEEE Trans. Comput." },
  { title: "Journal of the ACM", label: "J. ACM" },
  { title: "Computer Networks", label: "Comput. Netw." },
  { title: "Communications of the ACM", label: "Commun. ACM" },
  { title: "Quantum Information Processing", label: "Quantum Inf. Process." },
  { title: "Quantum Science and Technology", label: "Quantum Sci. Technol." },
  { title: "npj Quantum Information", label: "npj Quantum Inf." },
  {
    title: "Physical Review A - Quantum mechanics section",
    label: "Phys. Rev. A",
  },
  { title: "Quantum", label: "Quantum" },
];

export const academicDisciplines = [
  { title: "Computer Science", value: "Computer Science" },
  { title: "Medicine", value: "Medicine" },
  { title: "Chemistry", value: "Chemistry" },
  { title: "Biology", value: "Biology" },
  { title: "Materials Science", value: "Materials Science" },
  { title: "Physics", value: "Physics" },
  { title: "Geology", value: "Geology" },
  { title: "Psychology", value: "Psychology" },
  { title: "Art", value: "Art" },
  { title: "History", value: "History" },
  { title: "Geography", value: "Geography" },
  { title: "Sociology", value: "Sociology" },
  { title: "Business", value: "Business" },
  { title: "Political Science", value: "Political Science" },
  { title: "Economics", value: "Economics" },
  { title: "Philosophy", value: "Philosophy" },
  { title: "Mathematics", value: "Mathematics" },
  { title: "Engineering", value: "Engineering" },
  { title: "Environmental Science", value: "Environmental Science" },
  {
    title: "Agricultural and Food Sciences",
    value: "Agricultural and Food Sciences",
  },
  { title: "Education", value: "Education" },
  { title: "Law", value: "Law" },
  { title: "Linguistics", value: "Linguistics" },
];

export const minCitationArr = [
  "10",
  "100",
  "250",
  "500",
  "1000",
  "10000",
  "100000",
  "Custom",
];

export const sortOptions = [
  {
    type: "Publication Date",
    sorts: [
      { label: "Asc", value: "publicationDate:asc" },
      { label: "Desc", value: "publicationDate:desc" },
    ],
  },
  {
    type: "Citation Count",
    sorts: [
      { label: "Asc", value: "citationCount:asc" },
      { label: "Desc", value: "citationCount:desc" },
    ],
  },
  {
    type: "Paper Name",
    sorts: [
      { label: "A-Z", value: "title:asc" },
      { label: "Z-A", value: "title:desc" },
    ],
  },
  {
    type: "Author Name",
    sorts: [
      { label: "A-Z", value: "authors:asc" },
      { label: "Z-A", value: "authors:desc" },
    ],
  },
];

export const sortOptionsAuthor = [
  {
    type: "Paper Count",
    sorts: [
      { label: "Asc", value: "paperCount:asc" },
      { label: "Desc", value: "paperCount:desc" },
    ],
  },
  {
    type: "Citation Count",
    sorts: [
      { label: "Asc", value: "citationCount:asc" },
      { label: "Desc", value: "citationCount:desc" },
    ],
  },
  {
    type: "Author Name",
    sorts: [
      { label: "A-Z", value: "name:asc" },
      { label: "Z-A", value: "name:desc" },
    ],
  },
];

export const publicationDateAndYearArr = [
  "Exact Date Search",
  "Date Range",
  "Year Range",
];
