import { Icon } from "@iconify/react";
import React from "react";
import "../assets/css/Paginator.css";

const Paginator = ({
  totalPages,
  pageCount,
  goToPageOne,
  nextPage,
  previousPage,
  loading,
}) => {
  return (
    <div className="paginator-div-main">
      <div className="paginator-div-go-to-page" onClick={goToPageOne}>
        GO TO PAGE 01
      </div>
      <div className="paginator-div-pagination">
        {pageCount !== 1 && (
          <div className="paginator-div-pagination-back" onClick={previousPage}>
            <Icon icon="ph:arrow-right-bold" color="#ff7133" rotate={2} />
          </div>
        )}
        {!(totalPages === 0 || pageCount === totalPages) && (
          <div className="paginator-div-pagination-next" onClick={nextPage}>
            NEXT PAGE
            <Icon
              icon="ph:arrow-right-bold"
              color="#FFF"
              style={{ fontSize: "20px" }}
            />
          </div>
        )}
      </div>
      <div className="paginator-div-pagination-record">
        PAGE{" "}
        <span className="paginator-div-pagination-current-page">
          {pageCount}
        </span>
        OF {loading ? "loading..." : totalPages || 1}
      </div>
    </div>
  );
};

export default Paginator;
