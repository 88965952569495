import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const FieldsOfStudy = ({
  academicDisciplines,
  fieldsOfStudy,
  handleChangeFieldsOfStudy,
}) => {
  return (
    <div className="modal-filter-div">
      <span className="modal-filter-div-title">Fields Of Study</span>
      <div className="modal-filter-div-checkboxes">
        {academicDisciplines.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                style={{
                  color: "#4918A6",
                }}
                checked={fieldsOfStudy.indexOf(item.value) !== -1}
                onChange={(e) => handleChangeFieldsOfStudy(e.target.name)}
                name={item.value}
              />
            }
            label={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default FieldsOfStudy;
