import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "../../assets/css/FeedbackModal.css";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import { Rating } from "@mui/material";
import { addFeedbackForm } from "../../services/Feedback.service";

const style = {
  position: "absolute",
  top: window.innerWidth > 500 ? "55%" : "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth - 20,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  outline: "none",
  zIndex: "0",
  backgroundColor: "#FAF9F6",
  borderRadius: "13px",
  maxWidth: "400px",
  maxHeight: "90vh",
};

export default function FeedbackModal({ open, handleClose }) {
  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  const [stars, setStars] = React.useState(0);
  const [feedback, setFeedback] = React.useState("");

  const submitFeedback = async (e) => {
    e.preventDefault();
    try {
      if (feedback.trim().length === 0 && stars === 0) {
        Swal.fire("Info", "Please complete the form.", "info");
        return;
      }
      const formData = new FormData();
      formData.append("comment", feedback);
      formData.append("rating", stars);
      const response = await addFeedbackForm(formData);
      Swal.fire("Success", response.data?.message, "success").then(() =>
        handleClose(),
      );
    } catch (err) {
      Swal.fire(
        "Please Login to give feedback",
        err?.response?.data,
        "error",
      ).then(() => handleClose());
    }
  };

  React.useEffect(() => {
    setFeedback("");
    setStars(0);
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal-header">
              <div className="modal-header-title">
                <span>Feedback Form!</span>
              </div>
              <div
                className="modal_close"
                style={{ marginLeft: "auto" }}
                onClick={() => handleClose()}
              >
                <Icon icon="maki:cross" />
              </div>
            </div>
            <div className="modal-body">
              <h4
                style={{
                  textAlign: "center",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Did you like our platform?
              </h4>
              <Box
                sx={{
                  ml: 2,
                  color: "#41169C",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {labels[stars]}
              </Box>

              <div className="feedback-form-div">
                <Rating
                  name="simple-controlled"
                  value={stars}
                  size={"large"}
                  onChange={(event, newValue) => {
                    setStars(newValue);
                  }}
                />
                <form style={{ width: "100%" }} onSubmit={submitFeedback}>
                  <textarea
                    className="feedback-form-textfield"
                    rows="4"
                    cols="50"
                    placeholder="Write your feedback..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    type="textarea"
                  />
                  <button type="submit" className="feedback-btn">
                    Send Feedback
                  </button>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
