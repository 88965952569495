import React from "react";

const ProductInsightCard = ({ children, ...rest }) => {
  return (
    <div className="product-insights-div-main" {...rest}>
      <div className="product-insights-div-card">{children}</div>
    </div>
  );
};

export default ProductInsightCard;
