import React from "react";
import swooshPurple from "../../assets/images/swoosh-purple.webp";
import ourMissionsPoster from "../../assets/images/ourMissionPoster.webp";

const OurMissions = () => {
  return (
    <div className="our-missions-div">
      <div className="text-flex-img-div" style={{ alignItems: "center" }}>
        <img
          src={ourMissionsPoster}
          alt="poster"
          className="text-flex-img-div-img"
        />
        <div style={{ textAlign: "left" }} className="text-flex-img-div-div">
          <span className="text-with-swoosh-purple">
            {/* <span className="sub-div-heading">Our Missions</span> */}
            <span className="sub-div-heading">The Papertalk Promise</span>

            <img src={swooshPurple} alt="swoosh" className="swoosh" />
          </span>
          <span style={{ display: "block" }}>
            Imagine a world where half of all treasures remain hidden, and nine
            out of ten sparks of genius never catch fire. That's the reality for
            over 64 million research papers since 1996—unseen, unused,
            uncelebrated. Papertalk is changing that. We're here to unearth
            these hidden gems and bring them into the light of day.
            <br />
            <br />
            With our help, researchers and technologists can quickly leap into
            the heart of new knowledge, making every minute count. Our platform
            transforms complex data into easy-to-digest formats, turning what
            was once overlooked into a foundation for innovation and expertise.
            At Papertalk, we're not just filling gaps; we're expanding the realm
            of possibility for curious minds everywhere.
          </span>
        </div>
      </div>
    </div>
  );
};

export default OurMissions;
