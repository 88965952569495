import { Icon } from "@iconify/react";
import { Avatar } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import "../assets/css/Library.css";
import SimpleBackdrop from "../components/Backdrop";
import FeedbackModal from "../components/FeedbackModals/FeedbackModal";
import FloatingNavigation from "../components/HomepageSections/FloatingNavigation";
import Loader from "../components/Loader";
import LibraryCard from "../components/MyLibraryComponents/LibraryCard";
import { profileDetails } from "../services/Auth.services";
import { fetchmyLibraryDetails } from "../services/MyLibrary.services";
import { uploadPDFService } from "../services/UploadPDF.services";

const MyLibrary = () => {
  const navigate = useNavigate();
  const profilePicUrl = window.localStorage.getItem("profilePicUrl");
  const name = window.localStorage.getItem("name");
  const uploadLimit = window.localStorage.getItem("uploadLimit");
  const [papers, setPapers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [uploading, setUploading] = React.useState(false);
  const [feedbackForm, setFeedbackForm] = React.useState(false);
  const [uploadPDF, setUploadPDF] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const handleProfile = async () => {
      try {
        const response = await profileDetails();
        if (response.data) {
          const userData = await response.data;
          window.localStorage.setItem("email", userData.email);
          window.localStorage.setItem("name", userData.name);
          window.localStorage.setItem("profilePicUrl", userData.profilePicUrl);
          window.localStorage.setItem("uploadLimit", userData.uploadLimit);
        }
        // Update the local state
      } catch (error) {
        console.error("Logout failed:", error);
      }
    };
    const fetchPapers = async () => {
      try {
        const response = await fetchmyLibraryDetails();
        setIsLoading(true);
        const data = await response.data;
        setPapers(data || []);
        setFeedbackForm(
          (Number(data.length) !== 0 && Number(data?.length) % 5 === 0) ||
            false,
        );
        setIsLoading(false);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.error || "Something went wrong!.",
        }).then(() => window.location.reload());
        setIsLoading(false);
      }
    };
    fetchPapers();
    handleProfile();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const uploadPDFHandler = async () => {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append("file", uploadPDF);
        const response = await uploadPDFService(formData);
        if (response.data.paperId) {
          const { paperId, sementicScholarPaperId, publicUrl } = response.data;
          setUploading(false);
          navigate(
            `/understanding-page?paperId=${paperId}&sementicScholarPaperId=${sementicScholarPaperId}&publicUrl=${publicUrl}`,
          );
        }
      } catch (err) {
        setUploading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.error || "Something went wrong!.",
        });
        console.log(err);
      }
    };
    if (uploadPDF) {
      uploadPDFHandler();
    }
    // eslint-disable-next-line
  }, [uploadPDF]);

  return (
    <div className="library-page-main">
      <Helmet>
        <title>My Library | Papertalk.io </title>
        <meta
          name="My Library | Papertalk.io"
          content="Papertalk.io Privacy Page"
        />
      </Helmet>
      <FeedbackModal
        open={feedbackForm}
        handleClose={() => setFeedbackForm(false)}
      />
      {uploading && <SimpleBackdrop open={uploading} />}
      <div className="library-page-main-header">
        <div className="library-page-main-header-div">
          <div className="library-page-main-header-searchbar-combined">
            <div className="library-page-main-header-profile-section">
              <Avatar
                alt="profile-imgc"
                sx={{ width: "80px", height: "80px" }}
                src={profilePicUrl}
              />
              <div className="library-page-main-header-profile-section-details">
                <div className="library-page-main-header-profile-details-name">
                  {name}
                </div>
                <div className="library-page-main-header-profile-details-promotion">
                  <Icon
                    icon="ant-design:flag-outlined"
                    color="white"
                    style={{ fontSize: "20px" }}
                  />
                  <span>
                    Papers uploaded: {papers?.length}/{uploadLimit}
                  </span>
                </div>
              </div>
            </div>
            <div className="library-page-main-header-search-section">
              <div className="library-page-main-header-searchbar">
                <Icon
                  icon="bi:search"
                  color="#41169c"
                  style={{
                    fontSize: "20px",
                    marginRight: "20px",
                    marginLeft: "10px",
                  }}
                />
                <input
                  placeholder="Search keywords"
                  className="library-page-main-header-searchbar-input"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
              </div>
              <div
                className="library-page-main-header-searchbar-upload-pdf-btn"
                onClick={() => {
                  if (window.localStorage.getItem("email"))
                    document.getElementById("upload-pdf-input-library").click();
                  else
                    Swal.fire({
                      title: "You are not logged in",
                      text: "Please login to use upload PDF",
                      icon: "question",
                    });
                }}
              >
                <input
                  type={"file"}
                  id="upload-pdf-input-library"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setUploadPDF(event.currentTarget.files[0]);
                  }}
                />
                <Icon
                  icon="ep:upload-filled"
                  color="#41169c"
                  style={{ fontSize: "22px" }}
                />
                Upload PDF
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="library-page-body">
        <div className="researches-flex-listing-body">
          {papers
            ?.filter((item) =>
              item?.textSummaries?.title
                .toLowerCase()
                .includes(searchText.toLowerCase()),
            )
            ?.map((searched, index) => (
              <LibraryCard
                sementicScholarPaperId={searched?.paper?.sementicScholarPaperId}
                paperId={searched.paper?.paperId}
                key={index}
                audioUrl={searched.audioSummaries?.publicUrl}
                venue={searched.venue}
                author={
                  Array.isArray(searched.textSummaries?.author)
                    ? searched.textSummaries?.author
                    : searched.textSummaries?.author.indexOf(",") !== -1
                      ? searched.textSummaries?.author.split(",")
                      : searched.textSummaries?.author.split("")
                }
                title={searched.textSummaries.title}
                date={searched.publicationDate}
                description={searched?.textSummaries?.overview}
                publicUrl={searched.paper?.pdfPublicUrl}
              />
            ))}
        </div>
        {papers.length === 0 && !isLoading && (
          <div className="no-data-found-div">
            <p>No data found.</p>
          </div>
        )}
        {isLoading && <Loader />}
      </div>
      <FloatingNavigation />
    </div>
  );
};

export default MyLibrary;
