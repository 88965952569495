import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const VenuesFilter = ({ journals, venues, handleChangeVenues }) => {
  return (
    <div className="modal-filter-div">
      <span className="modal-filter-div-title">Venues</span>
      {/* <br /> */}
      <Autocomplete
        multiple
        id="tags-outlined"
        options={journals}
        style={{ marginTop: "5px" }}
        freeSolo
        getOptionLabel={(option) => option?.title || option}
        value={venues}
        onChange={(event, newValue) => {
          const filteredTitles = newValue.map((element) => {
            return element?.title || element;
          });
          handleChangeVenues(filteredTitles);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            color="info"
            size="small"
            label=""
            placeholder="Select Venues"
          />
        )}
      />
    </div>
  );
};

export default VenuesFilter;
