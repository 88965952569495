import instance from "../axios/interceptor";
import { fixedEncodeURIComponent } from "../utils/textHelpers";

export const getSearchResults = async (
  searchType = "paper",
  limit = "20",
  query = "",
  sort = "",
  pageCount = 0,
  filters,
  config
) => {
  const response = await instance(
    `${
      process.env.REACT_APP_SERVER_API
    }/search/${searchType}?limit=${limit}&query=${fixedEncodeURIComponent(
      query,
    )}&sort=${fixedEncodeURIComponent(sort)}&offset=${
      limit * (pageCount - 1)
    }&${filters}`
  );
  return response;
};

export const getAuthorPapersResults = async (
  authorId,
  limit = "20",
  query = "",
  sort = "",
  pageCount = 0,
  filters,
) => {
  const response = await instance(
    `${
      process.env.REACT_APP_SERVER_API
    }/search/author/${authorId}/papers?limit=${limit}&query=${fixedEncodeURIComponent(
      query,
    )}&sort=${fixedEncodeURIComponent(sort)}&offset=${
      limit * (pageCount - 1)
    }${filters}`,
  );
  return response;
};

export const getSuggestionsResults = async (query = "") => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/search/paper/autocomplete?query=${query}`,
  );
  return response;
};
