import instance from "../axios/interceptor";

export const addFeedbackForm = async (formData) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/feedback`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      method: "POST",
      data: formData,
    },
  );
  return response;
};

export const addRequestForm = async (formData) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/profile/increase_limit`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      method: "POST",
      data: formData,
    },
  );
  return response;
};
