import instance from "../axios/interceptor";

export const fetchmyLibraryDetails = async () => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/profile/library`,
    {
      withCredentials: true,
    },
  );
  return response;
};
