import React from "react";

const FilterTag = ({ title, count, cursor = "pointer" }) => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "15px",
        cursor: cursor,
      }}
    >
      {title} {count && <span className="tags-count">{count}</span>}
    </span>
  );
};

export default FilterTag;
