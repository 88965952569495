import { FormHelperText } from "@mui/material";
import React from "react";
import { sanitizePositiveNumber } from "../../utils/textHelpers";

const AuthorPageCountRange = ({
  authorPageCountRange,
  handleChangePageCountRange,
}) => {
  return (
    <div className="modal-filter-div" style={{ borderBottom: "0px" }}>
      <span className="modal-filter-div-title">Page Count Range: </span>
      <div style={{ width: "100%", margin: "auto" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            fontSize: "14px",
            columnGap: "20px",
          }}
        >
          <span>
            Min:{" "}
            <input
              className="authorCitationInput"
              type={"text"}
              value={authorPageCountRange.min}
              onChange={(e) =>
                handleChangePageCountRange(
                  sanitizePositiveNumber(e.target.value),
                  "min",
                )
              }
            />
          </span>
          <span>
            Max:{" "}
            <input
              className="authorCitationInput"
              type={"text"}
              value={authorPageCountRange.max || ""}
              onChange={(e) =>
                handleChangePageCountRange(
                  sanitizePositiveNumber(e.target.value),
                  "max",
                )
              }
            />
          </span>
        </div>
      </div>
      {authorPageCountRange.min > (authorPageCountRange?.max || 400000) && (
        <FormHelperText error={true}>Error: Range is not valid</FormHelperText>
      )}
    </div>
  );
};

export default AuthorPageCountRange;
