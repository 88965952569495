import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { formatNumber } from "../../utils/textHelpers";
import DatePickerByMode from "../DatePickerByMode";

const PublicationYear = ({
  publicationDateAndYearArr,
  setPublicationDateOrYearError,
  setPublicationDateOrYearFormat,
  setPublicationDateOrYearValue,
  publicationDateOrYearFormat,
  publicationDateOrYearValue,
  publicationDateOrYearError,
}) => {
  return (
    <div className="modal-filter-div">
      <span className="modal-filter-div-title">Publication Date And Year</span>
      <div style={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="publicationYearDate"
          name="radio-buttons-group"
          onChange={(e) => {
            setPublicationDateOrYearFormat(e.target.value);
            setPublicationDateOrYearValue({
              startDate: null,
              endDate: null,
            });
          }}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            width: "100%",
          }}
        >
          {publicationDateAndYearArr.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                checked={item === publicationDateOrYearFormat}
                value={item}
                control={
                  <Radio
                    style={{
                      color: "#4918A6",
                    }}
                  />
                }
                label={formatNumber(item)}
              />
            </div>
          ))}
        </RadioGroup>
      </div>
      {
        <div className="date-picker-main-div">
          <DatePickerByMode
            publicationDateOrYearFormat={publicationDateOrYearFormat}
            setPublicationDateOrYearError={setPublicationDateOrYearError}
            setPublicationDateOrYearValue={setPublicationDateOrYearValue}
            publicationDateOrYearValue={publicationDateOrYearValue}
          />
        </div>
      }
      {publicationDateOrYearError && <span>Please enter value</span>}
    </div>
  );
};

export default PublicationYear;
