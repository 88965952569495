import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Icon } from "@iconify/react";

export default function FilterTagWithModal({
  title,
  appliedFilter,
  callFilterAPI,
  children,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <div>
      <span
        onClick={handleClickOpen}
        style={{ display: "flex", flexDirection: "row", columnGap: "15px" }}
      >
        {title}{" "}
        {appliedFilter?.length > 0 && (
          <span className="tags-count">{appliedFilter?.length}</span>
        )}
      </span>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <div className="modal-header">
          <div className="modal-header-title">
            <span>Filter</span>
          </div>
          <div className="modal_close" onClick={() => handleClose()}>
            <Icon icon="maki:cross" />
          </div>
        </div>
        <DialogContent style={{ paddingBottom: "0px" }}>
          {children}
        </DialogContent>
        <DialogActions>
          <div className="modal-footer-btn-text" onClick={handleClose}>
            Cancel
          </div>
          <div
            className="modal-footer-btn"
            onClick={() => {
              callFilterAPI();
              handleClose();
            }}
          >
            Ok
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
