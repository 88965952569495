import { Icon } from "@iconify/react";
import React from "react";
import "../assets/css/Footer.css";
import { Link, useNavigate } from "react-router-dom";

import logo from "../assets/images/logo-white.webp";
import FeedbackModal from "./FeedbackModals/FeedbackModal";

const Footer = () => {
  const navigate = useNavigate();
  const [feedbackForm, setFeedbackForm] = React.useState(false);
  const handleClickSection = (e, value) => {
    e.preventDefault();
    const element = document.getElementById(value.split(".")[0]);

    if (window.location.pathname === "/") {
      if (element) {
        const scrollOffset = 90;

        setTimeout(() => {
          window.scrollTo({
            top: element.offsetTop - scrollOffset,
            behavior: "smooth",
          });
        }, 100);
      }
    } else {
      navigate(`/?id=${value}`);
    }
  };
  return (
    <footer className="footer-main">
      <FeedbackModal
        open={feedbackForm}
        handleClose={() => setFeedbackForm(false)}
      />
      <div className="footer-main-flex">
        <div className="footer-with-logo">
          <img className="footer-logo" src={logo} alt="papertalk.io" />
          <div className="footer-description">
            <span>Unlock the Power of Research Swiftly, Simply, Smartly</span>
          </div>
          <div style={{ width: "100%", marginTop: "15px" }}>
            <button
              className="upgrade-modal-button-orange"
              onClick={() => setFeedbackForm(true)}
              style={{ width: "100%", borderRadius: "5px", maxWidth: '200px' }}
            >
              Rate our website
            </button>
          </div>
        </div>
        <div className="flex-column-mobile">
          <div className="footer-main-with-links">
            <span className="footer-main-with-links-heading">Useful Links</span>
            <Link
              to="/"
              onClick={(e) => handleClickSection(e, "#")}
              className="footer-main-with-links-link"
            >
              Home
            </Link>
            <Link
              to="/"
              id="products_li.footer"
              onClick={(e) => handleClickSection(e, "products")}
              className="footer-main-with-links-link"
            >
              Product
            </Link>
            <Link
              to="/"
              id="teams_li.footer"
              onClick={(e) => handleClickSection(e, "teams")}
              className="footer-main-with-links-link"
            >
              Teams
            </Link>
          </div>
          <div className="footer-main-with-links">
            <span className="footer-main-with-links-heading">
              Help & Support
            </span>
            <Link
              to="/"
              id="faq_li.footer"
              onClick={(e) => handleClickSection(e, "faq")}
              className="footer-main-with-links-link"
            >
              FAQs
            </Link>
            <Link
              id="privacy-li"
              to={"/privacy-policy"}
              className="footer-main-with-links-link"
            >
              Privacy Policy
            </Link>
          </div>
        </div>

        <div className="footer-main-with-download-links">
          <span className="footer-main-with-links-heading">Contact Us</span>
          <div>
            <a
              href={"mailto:papertalkio.business@gmail.com"}
              style={{
                fontSize: "0.9rem",
                fontWeight: "600",
                color: "#fff",
                textDecoration: "none",
              }}
            >
              papertalkio.business@gmail.com
            </a>
          </div>
          <span className="footer-main-with-links-link">Follow us :</span>
          <div className="download-links-div">
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.linkedin.com/company/papertalkio"
              aria-label="linkedin-link"
              className="follow-us-link"
            >
              <Icon
                icon="ri:linkedin-fill"
                style={{ fontSize: "22px" }}
                color="#ff7133"
              />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href={"https://twitter.com/papertalk_io"}
              className="follow-us-link"
              aria-label="twitter-link"
            >
              <Icon
                icon="simple-icons:x"
                style={{ fontSize: "22px" }}
                color="#ff7133"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-ending-div">
        © Copyrights {new Date().getFullYear()}. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
