import { Icon } from "@iconify/react";
import React, { useRef, useState, useEffect } from "react";
import "../assets/css/FAQ.css";

const FAQ = ({ question, answer, ...rest }) => {
  const [expanded, setExpanded] = useState(false);
  const [answerHeight, setAnswerHeight] = useState("0px");
  const answerRef = useRef(null);

  useEffect(() => {
    if (expanded) {
      setAnswerHeight(`${answerRef.current.scrollHeight}px`);
    } else {
      setAnswerHeight("0px");
    }
  }, [expanded]);

  return (
    <div
      className={`faq-main-div ${expanded ? "expanded" : ""}`}
      onClick={() => setExpanded(!expanded)}
      {...rest}
    >
      <div className="faq-main-div-header">
        <Icon
          icon="ep:question-filled"
          color="#41169c"
          style={{ fontSize: "22px" }}
        />
        <div className="faq-main-div-header-question">{question}</div>
      </div>
      <div
        className="faq-main-div-answer"
        ref={answerRef}
        style={{
          maxHeight: answerHeight,
          overflow: "hidden",
          transition: "max-height 0.3s ease",
        }}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default FAQ;
