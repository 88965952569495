import swooshPurple from "../../assets/images/swoosh-purple.webp";
import tj from "../../assets/images/TJ.webp";
import hp from "../../assets/images/HP.webp";
import dev from "../../assets/images/Dev.webp";
import TeamCard from "../HomepageComponents/TeamCard";

const OurTeams = () => {
  return (
    <div className="product-insights-div" id="teams">
      <span className="text-with-swoosh-purple">
        <span className="sub-div-heading">Meet Our Team</span>
        <img src={swooshPurple} alt="swoosh" className="swoosh" />
      </span>
      <div className="team-card-flex">
        <TeamCard
          data-aos={"flip-right"}
          name={"Tirth Jayswal"}
          profilePhoto={tj}
          role={"Co-Founder"}
          socialLinks={{
            email: "tirthjayswal@gmail.com",
            linkedin: "https://www.linkedin.com/in/tirth-jayswal/",
          }}
        />
        <TeamCard
          data-aos={"flip-left"}
          name={"Devanshu Brahmbhatt"}
          profilePhoto={dev}
          role={"Co-Founder"}
          socialLinks={{
            email: "devanshu.vguj@gmail.com",
            linkedin: "https://www.linkedin.com/in/devanshubrahmbhatt/",
          }}
        />
        <TeamCard
          data-aos={"flip-right"}
          name={"Harsh Patel"}
          profilePhoto={hp}
          role={"Co-Founder"}
          socialLinks={{
            email: "harshpatelmagic105@gmail.com",
            linkedin: "https://www.linkedin.com/in/theharshpat",
          }}
        />
      </div>
    </div>
  );
};

export default OurTeams;
