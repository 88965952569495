import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../assets/css/Library.css";
import "../assets/css/UnderstandingPage.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import Loader from "../components/Loader.jsx";
import { Icon } from "@iconify/react";
import { CircularProgress, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { ChatBot } from "../components/ChatBot";
import SimpleBackdrop from "../components/Backdrop";
import AudioPlayer from "../components/AudioPlayer";
import { useLocation } from "react-router-dom";
import {
  fetchResearchPaperDetails,
  fetchPDFDetails,
  fetchPublicPDFService,
  fetchRecommendationsOrReferencesOrCitation,
} from "../services/PDF.services";
import FeatureCard from "../components/UnderstandingPageComponents/FeatureCard";
import { convertToTitleCase } from "../utils/textHelpers";
import { Helmet } from "react-helmet";
import lost from "../assets/images/man.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UnderstandingPage = () => {
  const [numPages, setNumPages] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [scale, setScale] = React.useState(1);
  const [rotate, setRotate] = React.useState(0);
  const [explainationTab, setExplainationTab] = React.useState(1);
  const [mobileMode, setMobileMode] = React.useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const publicUrl = searchParams.get("publicUrl");
  const paperId = searchParams.get("paperId");
  const sementicScholarPaperId = searchParams.get("sementicScholarPaperId");
  const [listings, setListings] = React.useState([]);
  const [audioUrl, setAudioUrl] = React.useState(null);
  const [applyResearch, setApplyResearch] = React.useState(null);
  const [pdfBlob, setPdfBlob] = React.useState(null);
  const [textSummary, setTextSummary] = React.useState(null);

  const [featuresMode, setFeaturesMode] = React.useState("recommendation");
  const [limit, setLimit] = React.useState(5);
  const [pdfCORS, setPdfCORS] = React.useState(false);
  const [showViewMoreLoading, setShowViewMoreLoading] = React.useState(false);
  const [listingLoading, setListingLoading] = React.useState(false);

  const [audioLoader, setAudioLoader] = React.useState(false);
  const [pdfLoader, setPdfLoader] = React.useState(false);
  const [paperDetailsLoader, setPaperDetailsLoader] = React.useState(false);

  const [paperDetails, setPaperDetails] = React.useState({
    title: "",
    authors: [],
    publicationDate: "",
    citationCount: 0,
  });

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages.numPages);
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const fetchPDF = async () => {
      try {
        window.scrollTo(0, 0);
        setAudioLoader(true);
        setTextSummary(null);
        setAudioUrl(null);
        const response = await fetchResearchPaperDetails(
          paperId,
          sementicScholarPaperId,
        );
        const data = await response.data;
        setAudioUrl(data?.audioSummaries?.publicUrl || null);
        setTextSummary(data?.textSummaries || null);
        setApplyResearch(data?.applyResearch || null);
        setAudioLoader(false);
        if (
          sementicScholarPaperId.length === 0 ||
          sementicScholarPaperId === "undefined"
        ) {
          setPaperDetails({
            title: data?.textSummaries.title,
            authors: data?.textSummaries?.author.split(" ").map((author) => {
              return {
                id: null,
                name: author,
              };
            }),
          });
        }
      } catch (err) {
        setAudioLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.error || "Something went wrong!.",
        });
        console.log(err);
      }
    };

    if (
      (paperId !== "undefined" || sementicScholarPaperId !== "undefined") &&
      publicUrl !== "undefined"
    ) {
      fetchPDF();
    }
    // eslint-disable-next-line
  }, [sementicScholarPaperId, paperId]);

  React.useEffect(() => {
    const fetchPublicPdf = async () => {
      try {
        setPdfLoader(true);
        const response = await fetchPublicPDFService(publicUrl);
        setPdfLoader(false);
        if (response.status === 403) {
          setPdfCORS(true);
          return;
        }
        const blob = await response.blob();
        setPdfBlob(blob);
        setPdfLoader(false);
      } catch (error) {
        setPdfLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.error || "Something went wrong!.",
        });
        console.error("Error fetching PDF:", error);
      }
    };
    if (publicUrl !== "undefined") {
      fetchPublicPdf();
    }
  }, [publicUrl]);

  const downloadPdf = () => {
    window.open(publicUrl, "_blank").focus();
  };

  const fetchRecommendationsOrReferencesOrCitationFunc = async (id, mode) => {
    try {
      setListings([]);
      setListingLoading(true);
      const response = await fetchRecommendationsOrReferencesOrCitation(
        id,
        mode,
        5,
      );
      const data = await response.data;
      setListings(data?.data || []);
      setListingLoading(false);
    } catch (err) {
      setListingLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data?.error || "Something went wrong!.",
      });
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (
      sementicScholarPaperId !== "undefined" &&
      sementicScholarPaperId.length > 0
    ) {
      fetchRecommendationsOrReferencesOrCitationFunc(
        sementicScholarPaperId,
        featuresMode,
        5,
      );
    }
    // eslint-disable-next-line
  }, [sementicScholarPaperId, featuresMode]);

  const viewMoreHandler = async () => {
    try {
      setShowViewMoreLoading(true);
      const response = await fetchRecommendationsOrReferencesOrCitation(
        sementicScholarPaperId,
        featuresMode,
        limit + 5,
      );
      const data = await response.data;
      setListings(data?.data || []);
      setShowViewMoreLoading(false);
    } catch (err) {
      setShowViewMoreLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data?.error || "Something went wrong!.",
      });
      console.log(err);
    }
    setLimit(limit + 5);
  };

  React.useEffect(() => {
    const fetchPDF = async () => {
      try {
        setPaperDetails(null);
        setPaperDetailsLoader(true);
        const response = await fetchPDFDetails(sementicScholarPaperId);
        const data = await response.data;
        setPaperDetails(data.paper);
        setPaperDetailsLoader(false);
      } catch (err) {
        setPaperDetailsLoader(false);
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.error || "Something went wrong!.",
        });
      }
    };

    if (
      sementicScholarPaperId !== "undefined" &&
      sementicScholarPaperId.length > 0
    ) {
      fetchPDF();
    }
    // eslint-disable-next-line
  }, [sementicScholarPaperId]);

  return (
    <div className="library-page-main">
      <Helmet>
        <title>Understanding Page | Papertalk.io</title>
        <meta
          name="Understanding Page | Papertalk.io"
          content="Papertalk.io Understanding page."
        />
      </Helmet>
      <SimpleBackdrop open={audioLoader && paperDetailsLoader} />
      {publicUrl !== "undefined" ? (
        <>
          <div className="understanding-page-main-header">
            <div className="understanding-page-main-header-div">
              <div className="understanding-page-main-header-searchbar-combined">
                <div className="understanding-page-main-header-profile-section">
                  <div className="understanding-page-main-header-profile-section-details">
                    <h2
                      style={{
                        color: "#fff",
                        margin: "auto",
                        marginTop: "5px",
                        marginBottom: "15px",
                        width: "90%",
                        maxWidth: "1400px",
                      }}
                    >
                      {paperDetails?.title}
                    </h2>
                    {paperDetailsLoader && (
                      <CircularProgress style={{ color: "#fff" }} />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        rowGap: "20px",
                        justifyContent: "center",
                        columnGap: "20px",
                      }}
                    >
                      {paperDetails?.authors[0]?.name && (
                        <div
                          style={{
                            cursor: paperDetails?.authors[0].authorId
                              ? "pointer"
                              : "initial",
                          }}
                          className={"understanding-page-tag-btn"}
                          onClick={() => {
                            if (!paperDetails?.authors[0].authorId) return;
                            window
                              .open(
                                `/search?type=author&authorId=${paperDetails?.authors[0]?.authorId}&authorName=${paperDetails?.authors[0]?.name}`,
                                "_blank",
                              )
                              .focus();
                          }}
                        >
                          <Icon
                            icon="bxs:user"
                            fontSize={"18px"}
                            color="white"
                          />{" "}
                          {paperDetails?.authors[0]?.name}
                          {paperDetails?.authors[0].authorId && (
                            <Icon
                              icon="gridicons:external"
                              color="#fff"
                              fontSize={"18px"}
                            />
                          )}
                        </div>
                      )}
                      {paperDetails?.publicationDate && (
                        <div className={"understanding-page-tag-btn"}>
                          <Icon
                            icon="ion:calendar-outline"
                            fontSize={"18px"}
                            color="white"
                          />{" "}
                          {paperDetails?.publicationDate}
                        </div>
                      )}
                      {paperDetails?.venue && (
                        <div className={"understanding-page-tag-btn"}>
                          <Icon
                            icon="ion:newspaper-sharp"
                            fontSize={"18px"}
                            color="white"
                          />{" "}
                          {paperDetails?.venue}
                        </div>
                      )}
                      {paperDetails?.citationCount >= 0 &&
                        paperDetails?.publicationDate && (
                          <div className={"understanding-page-tag-btn"}>
                            {paperDetails?.citationCount} Citations
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-viewer-header-mobile">
            <div
              className={`feature-tab${mobileMode === 1 ? "-active" : ""}`}
              onClick={() => setMobileMode(1)}
            >
              PDF Preview
            </div>
            <div
              className={`feature-tab${mobileMode === 2 ? "-active" : ""}`}
              onClick={() => setMobileMode(2)}
            >
              Understanding
            </div>
          </div>
          <div className="understanding-page-main-div">
            {!pdfLoader && pdfCORS && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "0",
                  width: "100%",
                  zIndex: "9999",
                }}
              >
                <p
                  className="pdf-cors-err-div"
                  style={{
                    textAlign: "center",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  Download PDF from this link <br />
                  <a href={publicUrl} target="_blank" rel="noreferrer">
                    {publicUrl}
                  </a>
                  <br />
                  Use upload PDF feature for Understanding
                </p>
              </div>
            )}
            <div
              className="pdf-viewer"
              style={{
                display:
                  mobileMode === 1
                    ? "block"
                    : window.innerWidth > 900
                      ? "block"
                      : "none",
              }}
            >
              <div className="pdf-viewer-header">
                {!pdfLoader && (
                  <>
                    <div className="pdf-page-counter">
                      <div className="pdf-page-counter-current-page">
                        {currentPage}
                      </div>
                      /
                      <div className="pdf-page-counter-total-page">
                        {numPages}
                      </div>
                    </div>
                    <span style={{ color: "#fff" }}>|</span>
                  </>
                )}
                <div className="pdf-scaling-div">
                  <button
                    className="pdf-scaling-btn"
                    onClick={() => setScale(scale - 0.1)}
                  >
                    -
                  </button>
                  <span className="pdf-scale-title">
                    {Math.round(scale * 100)}%
                  </span>
                  <button
                    className="pdf-scaling-btn"
                    onClick={() => setScale(scale + 0.1)}
                  >
                    +
                  </button>
                </div>
                <span style={{ color: "#fff" }}>|</span>
                <button
                  className="pdf-scaling-btn"
                  onClick={() => setRotate((rotate + 90) % 360)}
                >
                  <Icon
                    icon="ic:baseline-rotate-90-degrees-ccw"
                    color="white"
                  />
                </button>
                {!pdfLoader && publicUrl !== "undefined" && (
                  <Tooltip title="Download Pdf" arrow>
                    <button
                      style={{
                        marginLeft: "auto",
                        padding: "0",
                        marginRight: "15px",
                        fontSize: "24px",
                      }}
                      className="pdf-scaling-btn"
                      onClick={downloadPdf}
                    >
                      <Icon
                        icon="ic:round-download"
                        color="white"
                        style={{ fontSize: "24px" }}
                      />
                    </button>
                  </Tooltip>
                )}
              </div>
              {/* {!numPages && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "calc(100% - 46px)",
              }}
            >
            </div>
          )} */}
              <div
                className="pdf-viewer-scroller"
                style={{
                  width: "100%",
                  overflow: "scroll",
                  height: "calc(100% - 56px)",
                  // position: 'relative'
                }}
              >
                {pdfLoader && (
                  <div style={{ maxHeight: "200px" }}>
                    <Loader />{" "}
                  </div>
                )}
                {!pdfCORS && pdfBlob && (
                  <Document
                    file={pdfBlob}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdf-viewer-parent"
                    rotate={rotate}
                  >
                    {Array.from(Array(numPages), (e, x) => (
                      <Page
                        width={
                          window.innerWidth > 900
                            ? window.innerWidth / 2 - 50
                            : window.innerWidth - 50
                        }
                        onMouseEnter={() => setCurrentPage(x + 1)}
                        onTouchEnd={() => setCurrentPage(x + 1)}
                        className={"pdf-viewer-child"}
                        devicePixelRatio={window.devicePixelRatio}
                        scale={scale}
                        key={x}
                        pageNumber={x + 1}
                      />
                    ))}
                  </Document>
                )}
              </div>
              {!audioLoader && (
                <div className="pdf-audio-footer">
                  <AudioPlayer src={audioUrl} />
                </div>
              )}
            </div>
            <div
              className="pdf-viewer-understanding"
              style={{
                display:
                  mobileMode === 2
                    ? "block"
                    : window.innerWidth > 900
                      ? "block"
                      : "none",
              }}
            >
              <div className="explaination-viewer-header">
                <div
                  className={`explaination-tab${
                    explainationTab === 1 ? "-active" : ""
                  }`}
                  onClick={() => {
                    if (pdfCORS) return;
                    setExplainationTab(1);
                  }}
                >
                  AI Explainations
                </div>
                <div
                  className={`explaination-tab${
                    explainationTab === 2 ? "-active" : ""
                  }`}
                  onClick={() => {
                    if (pdfCORS) return;
                    setExplainationTab(2);
                  }}
                >
                  Ask Chatbot
                </div>
                <div
                  className={`explaination-tab${
                    explainationTab === 3 ? "-active" : ""
                  }`}
                  onClick={() => {
                    if (pdfCORS) return;
                    setExplainationTab(3);
                  }}
                >
                  Apply Research
                </div>
              </div>
              {explainationTab === 1 && (
                <div className="explaination-viewer-main-div">
                  {audioLoader ? (
                    <Loader />
                  ) : (
                    <>
                      {textSummary?.overview && (
                        <div className="explaination-summary-div">
                          <h4>Overview</h4>
                          <span>{textSummary?.overview || ""}</span>
                        </div>
                      )}
                      {textSummary?.problem && (
                        <div className="explaination-summary-div">
                          <h4>Problem</h4>
                          <span>{textSummary?.problem || ""}</span>
                        </div>
                      )}
                      {textSummary?.solution && (
                        <div className="explaination-summary-div">
                          <h4>Solution</h4>
                          <span>{textSummary?.solution || ""}</span>
                        </div>
                      )}
                      {textSummary?.approach && (
                        <div className="explaination-summary-div">
                          <h4>Approach</h4>
                          <span>{textSummary?.approach || ""}</span>
                        </div>
                      )}
                      {textSummary?.architecture && (
                        <div className="explaination-summary-div">
                          <h4>Architecture</h4>
                          <span>{textSummary?.architecture || ""}</span>
                        </div>
                      )}
                      {textSummary?.conclusion && (
                        <div className="explaination-summary-div">
                          <h4>Conclusion</h4>
                          <span>{textSummary?.conclusion || ""}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {explainationTab === 2 && (
                <div className="explaination-chat-main-div">
                  {audioLoader ? <Loader /> : <ChatBot />}
                </div>
              )}
              {explainationTab === 3 && (
                <div className="explaination-viewer-main-div">
                  {audioLoader ? (
                    <Loader />
                  ) : (
                    <>
                      {Object.entries(applyResearch).map(([key, value]) => (
                        <div className="explaination-summary-div" key={value}>
                          <h4>
                            {convertToTitleCase(key)}
                            {/* {firstLetterCapital(key.replace(/_/g, ' '))} */}
                          </h4>
                          <span>{value}</span>
                        </div>
                      ))}
                      {/* {applyResearch?.realWorldApplications && (
                    <div className="explaination-summary-div">
                      <h4>Real World Applications</h4>
                      <span>{applyResearch?.realWorldApplications || ""}</span>
                    </div>
                  )}
                  {applyResearch?.implementationGuidelines && (
                    <div className="explaination-summary-div">
                      <h4>Implementation Guidelines</h4>
                      <span>
                        {applyResearch?.implementationGuidelines || ""}
                      </span>
                    </div>
                  )}
                  {applyResearch?.patentStartupOpportunities && (
                    <div className="explaination-summary-div">
                      <h4>Patent Startup Opportunities</h4>
                      <span>
                        {applyResearch?.patentStartupOpportunities || ""}
                      </span>
                    </div>
                  )}
                  {applyResearch?.collaborationOpportunities && (
                    <div className="explaination-summary-div">
                      <h4>Collaboration Opportunities</h4>
                      <span>
                        {applyResearch?.collaborationOpportunities || ""}
                      </span>
                    </div>
                  )}
                  {applyResearch?.researchAdvancement && (
                    <div className="explaination-summary-div">
                      <h4>Research Advancement</h4>
                      <span>{applyResearch?.researchAdvancement || ""}</span>
                    </div>
                  )}
                  {applyResearch?.creativeLeverage && (
                    <div className="explaination-summary-div">
                      <h4>Creative Leverage</h4>
                      <span>{applyResearch?.creativeLeverage || ""}</span>
                    </div>
                  )}
                  {applyResearch?.challenges && (
                    <div className="explaination-summary-div">
                      <h4>Challenges</h4>
                      <ul>
                        {applyResearch?.challenges.map((challenge) => (
                          <li>
                            <span>
                              {challenge}
                              <br />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                      <br />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="no-pdf-available-div">
          <img src={lost} alt="lost-man" />
          <h3>The pdf is not available.</h3>{" "}
        </div>
      )}
      <div className="other-features-div">
        <div className="feature-viewer-header">
          <div
            className={`feature-tab${
              featuresMode === "recommendation" ? "-active" : ""
            }`}
            onClick={() => setFeaturesMode("recommendation")}
          >
            RECOMMENDATION
          </div>
          <div
            className={`feature-tab${
              featuresMode === "citations" ? "-active" : ""
            }`}
            onClick={() => setFeaturesMode("citations")}
          >
            CITATION
          </div>
          <div
            className={`feature-tab${
              featuresMode === "references" ? "-active" : ""
            }`}
            onClick={() => setFeaturesMode("references")}
          >
            REFERENCE
          </div>
        </div>
        <div className="features-lisitng-flex">
          {listingLoading && <Loader />}
          {!listingLoading && listings.length === 0 && (
            <h3 style={{ textAlign: "center" }}>No data found.</h3>
          )}
          {listings.map((item, index) => {
            const object =
              featuresMode === "recommendation"
                ? item
                : featuresMode === "citations"
                  ? item.citingPaper
                  : item.citedPaper;
            return (
              <FeatureCard
                paperId={object?.paperId}
                key={object?.paperId}
                citationCount={object?.citationCount}
                venue={object?.venue}
                author={object?.authors}
                title={object?.title}
                date={object?.publicationDate}
                description={object?.abstract || ""}
                extra={object?.extra || ""}
              />
            );
          })}
        </div>
        {listings.length !== 0 && (
          <div className="view-more-flex">
            {showViewMoreLoading ? (
              <CircularProgress style={{ color: "rgb(255, 113, 51)" }} />
            ) : (
              !listingLoading && (
                <button className="view-more-btn" onClick={viewMoreHandler}>
                  View More
                </button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UnderstandingPage;
