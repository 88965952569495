import React from "react";
import { useNavigate } from "react-router-dom";

const FloatingNavigation = () => {
  const navigate = useNavigate();

  return (
    <div className="floating-navigation-div">
      <button
        className="floating-navigation-btn"
        onClick={() => navigate("/search?type=paper")}
      >
        Explore Research
      </button>
      {/* <button className="floating-navigation-btn">Trend Analysis</button> */}
    </div>
  );
};

export default FloatingNavigation;
