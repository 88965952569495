export function firstLetterCapital(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function fixedEncodeURIComponent(str) {
  let encodedUrl = encodeURIComponent(str);
  encodedUrl = encodedUrl.replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16)}`,
  );
  return encodedUrl;
}

export function shortenName(name) {
  if (!name) return "";
  return name;
  // const nameParts = name.split(" and ");

  // if (nameParts.length > 1) {
  //   const shortenedNames = nameParts.map((part) => {
  //     const individualNames = part.split(" ");
  //     const shortenedName = individualNames
  //       .map((name, index) => {

  //         return index === 0
  //           ? `${name}`
  //           : `${name.charAt(0)}${name.includes(".") ? "" : "."}`;
  //       })
  //       .join(" ");
  //     return shortenedName;
  //   });
  //   return shortenedNames.join(" and ");
  // } else {

  //   const individualNames = nameParts[0].split(" ");
  //   const shortenedName = individualNames
  //     .map((name, index) => {
  //       return index === 0
  //         ? `${name}`
  //         : `${name.charAt(0)}${name.includes(".") ? "" : "."}`;
  //     })
  //     .join(" ");
  //   return shortenedName;
  // }
}

export function addSpaces(input) {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b\w/g, (c) => c.toUpperCase());
}

export function formatNumber(number) {
  if (number >= 1000 && number < 1000000) {
    // Convert to thousands (k)
    return Math.round((number / 1000).toFixed(1)) + "k";
  } else {
    // Keep the number as is
    return number.toString();
  }
}

export function parseDatePickerInput(input) {
  if (/^\d{4}-\d{2}-\d{2}$/.test(input)) {
    return new Date(input);
  } else if (/^\d{4}-\d{2}$/.test(input)) {
    return true;
  } else if (/^\d{4}$/.test(input)) {
    return true;
  } else if (/^\d{4}-\d{2}-\d{2}:\d{4}-\d{2}-\d{2}$/.test(input)) {
    return true;
  } else if (/^:\d{4}-\d{2}-\d{2}$/.test(input)) {
    return true;
  } else if (/^\d{4}:\d{4}$/.test(input)) {
    return true;
  } else {
    return false;
  }
}

export function sanitizePositiveNumber(value) {
  const numberValue = Number(value);

  if (!isNaN(numberValue) && numberValue >= 0) {
    return numberValue;
  } else {
    return "";
  }
}

export function addObjectsWithUniqueProperty(
  existingArray,
  newObjects,
  property,
) {
  // Extract existing property values into a Set for faster lookup
  const existingPropertyValues = new Set(
    existingArray.map((obj) => obj[property]),
  );

  // Iterate through new objects
  for (const newObj of newObjects) {
    // Check if the object's property value already exists in the array
    if (!existingPropertyValues.has(newObj[property])) {
      // Add the object to the array
      existingArray.push(newObj);
      // Also add its property value to the Set for future checks
      existingPropertyValues.add(newObj[property]);
    }
  }

  // Return the updated array
  return existingArray;
}

export function deleteAllCookies() {
  var c = document.cookie.split("; ");
  for (let i in c) {
    document.cookie =
      /^[^=]+/.exec(c[i])[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function convertToTitleCase(inputString) {
  let words = inputString.split(/(?=[A-Z])/);

  const titleCaseString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return titleCaseString.replace(/_/g, " ");
}
