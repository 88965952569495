import React from "react";
import FAQ from "../FAQ";
import swooshPurple from "../../assets/images/swoosh-purple.webp";

const FAQSection = () => {
  const papertalkFAQs = [
    {
      question: "What is Papertalk?",
      answer:
        "Papertalk is more than just a platform; it's your gateway to the world of research. It's where curiosity meets clarity, enabling anyone to find, understand, and apply research effortlessly. We're not just making research accessible; we're making it approachable for everyone.",
    },
    {
      question: "How can Papertalk help me in my research?",
      answer:
        "Think of Papertalk as your personal research assistant. With advanced search filters, you can quickly unearth papers that matter to you. But we don't stop there - we help you grasp the essence of these papers, turning complex information into actionable knowledge.",
    },
    {
      question: "What makes Papertalk different from other research platforms?",
      answer:
        "While most platforms focus on just reading papers, Papertalk is all about understanding. We're committed to helping you digest intricate research in less time, transforming how you interact with academic knowledge.",
    },
    {
      question: "Is Papertalk suitable for non-academic users?",
      answer:
        "Absolutely! Papertalk is for the inquisitive minds, regardless of academic background. Whether you're exploring a new hobby or diving into professional research, our platform adapts to your curiosity with tailored search results.",
    },
    {
      question: "How do I access and use the audiobook feature?",
      answer:
        "Discovering the audiobook feature is like unlocking a treasure trove of knowledge. Once you find a paper, simply click 'Start Understanding' to see a PDF preview and access the audio player. This feature is also available in 'My Library', making learning flexible and convenient.",
    },
    {
      question: "Is there a subscription fee or is Papertalk free to use?",
      answer:
        "Embark on your Papertalk.io journey with ease! Currently, you have the ability to upload and analyze up to 10 papers absolutely free. If you find Papertalk.io beneficial and wish to increase your limit to 25 papers, simply fill out our request form available via the 'Upgrade' button. As this platform is a university student project, we appreciate your support and feedback in enhancing our service.",
    },
    {
      question: "Can't we just use ChatGPT for understanding research papers?",
      answer:
        "While ChatGPT is a powerful tool, Papertalk offers a more streamlined, end-to-end research solution. We eliminate the need for manual uploads or crafting prompts. Our AI transforms research papers into concise audiobooks and explanations, creating a seamless journey from discovery to knowledge.",
    },
  ];

  return (
    <div
      className="product-insights-div"
      id="faq"
      style={{ minHeight: "540px", paddingTop: "50px", paddingBottom: "50px" }}
    >
      <span className="text-with-swoosh-purple">
        <span className="sub-div-heading">
          FAQ - Frequently Asked Questions
        </span>
        <img src={swooshPurple} alt="swoosh" className="swoosh" />
      </span>
      <div className="faq-card-flex">
        {papertalkFAQs.map((faq) => (
          <FAQ key={faq.question} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
