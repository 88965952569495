import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import "../../assets/css/SearchCard.css";
import { fetchUnderstandingPDF } from "../../services/UploadPDF.services";
import { shortenName } from "../../utils/textHelpers";
import SimpleBackdrop from "../Backdrop";

const SearchCard = ({
  title,
  author,
  description,
  paperId,
  publicationTypes,
  date,
  fieldsOfStudy,
  handleClickToaster,
  citationCount,
  venue,
}) => {
  const [abbreviatedDescription, setAbbreviatedDescription] = React.useState(
    description.length > 50
      ? description.split(".").slice(0, 1).join(". ") + "..."
      : description,
  );

  const navigate = useNavigate();
  const [spinner, setSpinner] = React.useState(false);
  const email = window.localStorage.getItem("email");
  const [expand, setExpand] = React.useState(false);

  const startUnderstandingBtn = async () => {
    try {
      if (!email) {
        handleClickToaster("Understanding feature");
        return;
      }
      setSpinner(true);
      const response = await fetchUnderstandingPDF(paperId);
      if (response.data) {
        const {
          paperId = undefined,
          sementicScholarPaperId = undefined,
          publicUrl = undefined,
        } = response.data;
        setSpinner(false);
        navigate(
          `/understanding-page?paperId=${
            paperId || undefined
          }&sementicScholarPaperId=${
            sementicScholarPaperId || undefined
          }&publicUrl=${publicUrl || undefined}`,
        );
      }
    } catch (err) {
      setSpinner(false);
      console.log(err);
      if (err.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonText: "Increase your limit",
          text: err?.response?.data?.error || "Something went wrong!.",
        }).then((result) => {
          if (result.isConfirmed) {
            document.getElementById("upgrade-btn-id").click();
          } else return;
        });
        return;
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data?.error || "Something went wrong!.",
      });
    }
  };

  React.useEffect(() => {
    if (expand) setAbbreviatedDescription(description);
    else
      setAbbreviatedDescription(
        description.length > 50
          ? description.split(".").slice(0, 1).join(". ") + "..."
          : description,
      );
    // eslint-disable-next-line
  }, [expand]);

  return (
    <div className="research-card">
      {spinner && <SimpleBackdrop open={spinner} />}
      <div className="research-card-top-border" />
      <div className="research-card-header">
        <div className="purple-head-left-border" />
        <div className="research-card-title-div">
          <span className="research-card-title">{title}</span>
          <div
            className="start-understandin-btn"
            onClick={() => startUnderstandingBtn(paperId)}
          >
            <span className="start-understanding-btn-title">
              Start Understanding
            </span>
            <Icon icon="ph:arrow-right-bold" color="white" />
          </div>
        </div>
      </div>
      <div className="research-card-body">
        <div className="research-card-body-tags-date-flex">
          <div className="research-card-body-tags-flex">
            {author?.slice(0, 3).map((item, index) => (
              <div
                className="research-card-body-tag"
                style={{ cursor: "pointer", minWidth: "60px" }}
                key={`${index}_${item.authorId}`}
                onClick={() =>
                  window
                    .open(
                      `/search?type=author&authorId=${item.authorId}&authorName=${item.name}`,
                      "_blank",
                    )
                    .focus()
                }
              >
                <Icon icon="bi:person-fill" color="#4918a6" />
                <Tooltip title={item?.name}>
                  <span>{shortenName(item.name, 9)}</span>
                </Tooltip>
                <Icon icon="gridicons:external" color="#4918a6" />
              </div>
            ))}
            {citationCount !== 0 && (
              <div
                className="research-card-body-tag"
                style={{ cursor: "initial" }}
              >
                <Icon icon="ion:newspaper-sharp" color="#4918a6" />
                <span> Citation Count: {citationCount}</span>
              </div>
            )}
          </div>
          {date && (
            <div className="research-card-body-tag">
              <Icon icon="ion:calendar" color="#4918a6" />
              <span>{date}</span>
            </div>
          )}
        </div>
        <div className="research-card-body-tags-date-flex">
          <div className="research-card-body-tags-flex">
            {venue && (
              <div
                className="research-card-body-tag"
                style={{ minWidth: "60px" }}
              >
                <Icon icon="fa6-solid:place-of-worship" color="#4918a6" />
                <span>{venue}</span>
              </div>
            )}
          </div>
          {/* fields of study start */}
          <div
            className="research-card-body-tags-flex"
            style={{ justifyContent: "flex-end" }}
          >
            {fieldsOfStudy?.length > 0 &&
              fieldsOfStudy?.map((field, index) => (
                <div
                  className="research-card-body-tag"
                  style={{ minWidth: "60px" }}
                  key={index}
                >
                  <Icon icon="ion:book-sharp" color="#4918a6" />
                  <span>{field}</span>
                </div>
              ))}
          </div>
          {/* fields of study end */}
        </div>
        {/* publication type start */}
        <div
          className="research-card-body-tags-flex"
          style={{ justifyContent: "flex-start" }}
        >
          {publicationTypes?.length > 0 && (
            <div
              className="research-card-body-tag"
              style={{ minWidth: "60px" }}
            >
              <Icon icon="uiw:printer" color="#4918a6" />
              <span>{publicationTypes.join(", ")}</span>
            </div>
          )}
        </div>
        {/* publication type end */}
        {/* <br /> */}
        <div style={{ marginTop: "15px" }} />
        <span style={{ fontSize: "0.975rem" }}>
          {abbreviatedDescription}{" "}
          {description.length !== 0 && (
            <span
              className="feature-card-expand"
              onClick={() => setExpand(!expand)}
            >
              {expand ? "Show less" : "Show more"}
            </span>
          )}
        </span>
      </div>
      {/* <div className="research-card-footer">
        
      </div> */}
      <div className="research-card-bottom-border" />
    </div>
  );
};

export default SearchCard;
