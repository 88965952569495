import React from "react";
import "../assets/css/Home.css";
import HomeBanner from "../components/HomepageSections/HomeBanner";
import ProductInsights from "../components/HomepageSections/ProductInsights";
import AdvancedFeatures from "../components/HomepageSections/AdvancedFeatures";
import OurMissions from "../components/HomepageSections/OurMissions";
import ClientsTestimonial from "../components/HomepageSections/ClientsTestimonial";
import OurTeams from "../components/HomepageSections/OurTeams";
import FAQSection from "../components/HomepageSections/FAQSection";
import FloatingNavigation from "../components/HomepageSections/FloatingNavigation";
import "aos/dist/aos.css";
import AOS from "aos";
import { Helmet } from "react-helmet";

const Home = () => {
  React.useEffect(() => {
    const id = window.location.search.split("id=")[1];
    const element = document.getElementById(id);
    if (element) {
      const scrollOffset = 90;

      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - scrollOffset,
          behavior: "smooth",
        });
      }, 100);
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  AOS.init({ duration: 1200 });
  return (
    <div className="home-main">
      <Helmet>
        <title>Papertalk.io</title>
        <meta
          name="Papertalk.io"
          content="Papertalk.io is a platform designed to harness the power of research for everyone. Whether you're a student, researcher, or professional, our advanced filters help you quickly find research papers that matter to you. Our AI-powered explanations break down complex papers into understandable insights. Stuck on a tricky part? Just ask our chatbot research assistant. We go further by showing you how to apply these insights practically, making research not just accessible, but actionable. Join us to make research an asset in your work."
        />
      </Helmet>
      <HomeBanner />
      <ProductInsights />
      <AdvancedFeatures />
      <OurMissions />
      <ClientsTestimonial />
      <OurTeams />
      <div style={{ minHeight: "20px" }}></div>
      <FAQSection />
      <FloatingNavigation />
    </div>
  );
};

export default Home;
