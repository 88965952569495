import React from "react";
import { Helmet } from "react-helmet";
import "../assets/css/TnC.css";
const Privacy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tnc-main-body">
      <Helmet>
        <title>Privacy Policy | Papertalk.io</title>
        <meta
          name="Privacy Policy | Papertalk.io"
          content="Papertalk.io Privacy Page"
        />
      </Helmet>
      <div className="tnc-main-cover-bg">
        <h1>Privacy Policy</h1>
        {/* <span>
          We have unique items that can’t be found just anywhere. To keep you
          on-trend and always in something new, new items are regularly added to
          our inventory from both US and Japan.
        </span> */}
      </div>
      <div className="tnc-main-detail">
        <h4>Introduction</h4>
        <span>
          Welcome to Papertalk.io ! We are a student-led initiative focused on
          providing a platform for accessing and understanding research papers.
          Our goal is to learn and grow in the field of product development and
          to share this learning journey with our users.
          <br />
        </span>
        <h4>Content and Sources</h4>
        <span>
          Papertalk.io utilizes Semantic Scholar for searching research papers
          and the OpenAI LLM model for generating AI explanations and chatbot
          responses. We do not guarantee or assure the accuracy or reliability
          of the content provided. Our services are meant for educational
          purposes and are not intended for commercial use.
          <br />
        </span>
        <h4>User Accounts and Data</h4>
        <span>
          Users can create accounts using Google OAuth for authentication. We
          store only the necessary data provided by Google OAuth and do not
          collect additional personal information. User privacy is paramount,
          and we commit to not sharing any user data with third parties.
          <br />
        </span>
        <h4>No Guarantees</h4>
        <span>
          As a learning project, Papertalk.io does not offer any guarantees
          regarding the availability, continuity, or level of service. Users
          engage with the platform and its contents at their own risk.
        </span>
        <h4>Changes to Terms</h4>
        <span>
          Papertalk.io reserves the right to modify these terms and conditions
          and the privacy policy at any time. Users will be notified of
          significant changes.
        </span>
        <h4>Contact Us</h4>
        <span>
          For any inquiries or concerns regarding our privacy policy and terms
          and conditions, please contact us at papertalkio.business@gmail.com.
        </span>
        <h4>Acceptance of Terms</h4>
        <span>
          By using Papertalk.io , users acknowledge and accept these terms and
          conditions in full.
        </span>
        <br />
        <br />

        <span>
          Papertalk.io can make mistakes. Consider checking important
          information.
        </span>
      </div>
    </div>
  );
};

export default Privacy;
