import React from "react";
import swoosh from "../../assets/images/swoosh.webp";
import MessageDiv from "../HomepageComponents/MessageDiv";
import bg from "../../assets/images/Happy-Clients.webp";

const ClientsTestimonial = () => {
  const testimonials = [
    {
      name: "Aarushi Jain",
      role: "AMD Intern, Ex-Berkeley Lab Intern",
      msg: "Papertalk.io has streamlined my research workflow, making literature review a seamless process.",
      rating: 4,
    },
    {
      name: "Dharm Patel",
      role: "Embedded Engineer",
      msg: "Thanks to Papertalk.io, I can now understand complex papers quickly, making study sessions much more productive.",
      rating: 5,
    },
    {
      name: "Priyansh Prajapati",
      role: "Grad Student @ ASU",
      msg: "Finding innovative solutions is critical in my field, and Papertalk.io has become my go-to for quick, reliable research.",
      rating: 4,
    },
    {
      name: "Jay Desai",
      role: "Grad Student @ Guelph",
      msg: "Absorbing difficult research topics has become so much easier with Papertalk.io’s intuitive explanations.",
      rating: 4,
    },
    {
      name: "Nikita Kabade",
      role: "SDE @ Accenture",
      msg: "I'm reading and synthesizing information faster than ever with Papertalk.io. It's an indispensable tool in my research arsenal.",
      rating: 5,
    },
    {
      name: "Sangam Suthar",
      role: "MBA @ NMIMS",
      msg: "Papertalk.io keeps me updated with the latest technological advancements, enhancing my engineering projects.",
      rating: 4,
    },
    {
      name: "Mohak Chaudhary",
      role: "SDE @ Lentra",
      msg: "Papertalk.io has transformed how I engage with academic content, making learning an exciting adventure.",
      rating: 5,
    },
  ];

  return (
    <div
      className="advance-features-div"
      style={{ paddingBottom: "50px", backgroundImage: `url(${bg})` }}
    >
      <span className="sub-div-heading" style={{ color: "#fff" }}>
        Meet our Happy{" "}
      </span>
      <span className="text-with-swoosh-purple">
        <span className="sub-div-heading" style={{ color: "#fff" }}>
          Users
        </span>
        <img src={swoosh} alt="swoosh" className="swoosh" />
      </span>
      {/* <div className="scroll-parent">
        <div className="message-from-clients-flex scroll-element-right"> */}
      <div class="marquee">
        <div class="marquee--inner">
          {testimonials.map((item, index) => (
            <MessageDiv
              key={index}
              index={index}
              name={item.name}
              role={item.role}
              value={item.rating}
              message={item.msg}
            />
          ))}
        </div>
      </div>
      {/* <div className="scroll-parent">
        <div className="message-from-clients-flex scroll-element-left">
          {testimonials.slice(6).map((item, index) => (
            <MessageDiv
              key={index}
              index={index}
              name={item.name}
              value={item.rating}
              role={item.role}
              message={item.msg}
            />
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ClientsTestimonial;
