import { Icon } from "@iconify/react";
import React from "react";
import "../../assets/css/TeamCard.css";

const TeamCard = ({ profilePhoto, name, role, socialLinks, ...rest }) => {
  return (
    <div className="team-card" {...rest}>
      <div className="team-card-img-div">
        <img src={profilePhoto} className="team-card-img" alt={name} />
      </div>
      <div className="team-card-details">
        <span className="team-card-details-name">{name}</span>
        <span className="team-card-details-role">{role}</span>
      </div>
      <div className="team-card-social-links">
        <a
          id={socialLinks.linkedin}
          href={`${socialLinks.linkedin}`}
          target="_blank"
          rel={"noreferrer"}
          aria-label={`linkedin-id-${name}`}
          className="team-card-social-linkedin"
        >
          <Icon
            icon="ri:linkedin-fill"
            // aria-label={`linkedin-id-${name}`}
            color="#ff7133"
          />
        </a>
        <a
          id={socialLinks.email}
          href={`mailto:${socialLinks.email}`}
          aria-label={`email-id-${name}`}
          className="team-card-social-link"
        >
          <Icon
            icon="material-symbols:attach-email"
            // aria-label={`email-id-${name}`}
            color="#ff7133"
          />
        </a>
      </div>
    </div>
  );
};

export default TeamCard;
