import { Icon } from "@iconify/react";
import React from "react";
import "../../assets/css/AuthorCard.css";
import u1 from "../../assets/images/user(1).webp";
import u2 from "../../assets/images/user(2).webp";
import u3 from "../../assets/images/user(3).webp";
import u4 from "../../assets/images/user(4).webp";
import u5 from "../../assets/images/user(5).webp";
import u6 from "../../assets/images/user(6).webp";
import u7 from "../../assets/images/user(7).webp";

const AuthorCard = ({
  exploreAuthorPages,
  author,
  title,
  date,
  citationCount,
  paperCount,
  authorId,
  index,
}) => {
  const profiles = [u1, u2, u3, u4, u5, u6, u7];

  return (
    <>
      <div className="author-card">
        <div className="author-card-top-border" />
        <div className="author-card-header">
          <div className="purple-head-left-border" />
          <div className="author-card-title">
            <div className="author-card-title-profile">
              <img alt="profile" src={profiles[index % 7]} />
            </div>
            <span>{title}</span>
          </div>
        </div>
        <div>
          <div
            style={{
              width: "80%",
              marginLeft: "10%",
              marginTop: "10px",
              minHeight: "40px",
            }}
          >
            {citationCount !== 0 && (
              <div className="research-card-body-tag">
                <Icon icon="ion:newspaper-sharp" color="#4918a6" />
                <span> Citation Count: {citationCount}</span>
              </div>
            )}
          </div>
        </div>
        <div className="author-card-footer">
          <div
            className="sort-filter-btn-active"
            onClick={() => exploreAuthorPages(author)}
          >
            Explore All {paperCount || ""} Papers
            <Icon icon="ph:arrow-right-bold" color="white" />
          </div>
        </div>
        <div className="author-card-bottom-border" />
      </div>
    </>
  );
};

export default AuthorCard;
