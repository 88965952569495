import React from "react";
import "../../assets/css/SmallPurpleBox.css";

const SmallPurpleBox = ({ title, info, ...rest }) => {
  return (
    <div className="small-purple-box" {...rest}>
      <span className="small-purple-box-title">{title}</span>
      <span className="small-purple-box-info">{info}</span>
    </div>
  );
};

export default SmallPurpleBox;
