import PropTypes from "prop-types";
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
import Loader from "./Loader";

export default function SimpleBackdrop(props) {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props?.open}
      >
        <Loader />
      </Backdrop>
    </div>
  );
}

SimpleBackdrop.propTypes = {
  open: PropTypes.any,
};
