import { Icon } from "@iconify/react";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import banner from "../../assets/images/banner.webp";
import swoosh from "../../assets/images/swoosh.webp";
import SmallPurpleBox from "../HomepageComponents/SmallPurpleBox";

const HomeBanner = () => {
  const videoRef = useRef(null);

  // const handleScroll = () => {
  //   try {
  //     window.onscroll = () => {
  //       const video = videoRef.current;
  //       if (!video) return;
  //       const observer = new IntersectionObserver((entries, observer) => {
  //         entries.forEach((entry) => {
  //           if (entry.isIntersecting) {
  //             video.play();
  //             // video.playbackRate = 1.5;
  //           }
  //         });
  //       });
  //       observer.observe(document.querySelector("#myVideo"));
  //     };
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   handleScroll();
  //   // eslint-disable-next-line
  // }, [window.scrollY]);

  return (
    <>
      <div className="home-header-main" id="#">
        <div className="home-header">
          <div className="dotted-free-pdfs-banner">
            <Icon
              icon="ant-design:flag-outlined"
              color="white"
              style={{ fontSize: "24px" }}
            />
            <span>Upload 10 Papers Free !</span>
          </div>

          <h1 data-aos={"zoom-in"}>
            Unlock the Power of
            <span className="text-with-swoosh">
              <span>Research</span>
              <img src={swoosh} alt="swoosh" className="swoosh" />{" "}
            </span>
            <br />
            Swiftly, Simply, Smartly
          </h1>
          <button className="orange-btn" data-aos={"zoom-out-down"}>
            <Link className="link-span" to={"/search?type=paper"}>
              <span>
                GET STARTED
                {/* <span style={{ fontSize: "11px", display: "block" }}>
                  FREE ENTRY
                </span> */}
              </span>
              <Icon
                icon="icon-park-solid:right-c"
                color="white"
                style={{ fontSize: "28px" }}
              />
            </Link>
          </button>
        </div>
        <div className="relative-image-div" data-aos="zoom-in">
          <video
            ref={videoRef}
            className="banner"
            loop
            id="myVideo"
            poster={banner}
            autoPlay
            playsInline
            muted
          >
            <source
              width="100%"
              src={
                "https://res.cloudinary.com/papertalk-io/video/upload/v1704618687/ci52rqjc6hilxlfysij8.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div className="product-info-boxes-flex">
        <SmallPurpleBox
          data-aos={"flip-up"}
          title={"215+ Million"}
          info={"Papers"}
        />
        <SmallPurpleBox
          data-aos={"flip-down"}
          title={"4000+"}
          info={"Trusted Users"}
        />
        <SmallPurpleBox
          data-aos={"flip-up"}
          title={"5000+"}
          info={"AI Explanations"}
        />
        <SmallPurpleBox
          data-aos={"flip-down"}
          title={"70+ Million"}
          info={"Authors"}
        />
      </div>
    </>
  );
};

export default HomeBanner;
