import { Icon } from "@iconify/react";
import React from "react";
import "../../assets/css/FeatureCard.css";
import { shortenName } from "../../utils/textHelpers";
import { useNavigate } from "react-router";
import SimpleBackdrop from "../Backdrop";
import { fetchUnderstandingPDF } from "../../services/UploadPDF.services";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";

const FeatureCard = ({
  title,
  author,
  description,
  paperId,
  date,
  // extra,
  citationCount,
  // venue,
}) => {
  const [abbreviatedDescription, setAbbreviatedDescription] = React.useState(
    description.length > 50
      ? description.split(".").slice(0, 1).join(". ") + "..."
      : description,
  );

  const [expand, setExpand] = React.useState(false);
  const navigate = useNavigate();
  const [spinner, setSpinner] = React.useState(false);
  const email = window.localStorage.getItem("email");

  const uploadPDFHandler = async () => {
    try {
      if (!email) {
        // handleClickToaster();
        return;
      }
      setSpinner(true);
      const response = await fetchUnderstandingPDF(paperId);
      if (response.data) {
        const {
          paperId = undefined,
          sementicScholarPaperId = undefined,
          publicUrl = undefined,
        } = response.data;
        setSpinner(false);
        navigate(
          `/understanding-page?paperId=${
            paperId || undefined
          }&sementicScholarPaperId=${
            sementicScholarPaperId || undefined
          }&publicUrl=${publicUrl || undefined}`,
        );
      }
    } catch (err) {
      setSpinner(false);
      if (err.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonText: "Increase your limit",
          text: err?.response?.data?.error || "Something went wrong!.",
        }).then((result) => {
          if (result.isConfirmed) {
            document.getElementById("upgrade-btn-id").click();
          } else return;
        });
        return;
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data?.error || "Something went wrong!.",
      });
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: err?.response?.data?.error || "Something went wrong!.",
      // });
      // console.log(err);
    }
  };

  React.useEffect(() => {
    if (expand) setAbbreviatedDescription(description);
    else
      setAbbreviatedDescription(
        description.length > 50
          ? description.split(".").slice(0, 1).join(". ") + "..."
          : description,
      );
    // eslint-disable-next-line
  }, [expand]);

  return (
    <div className="feature-card">
      {spinner && <SimpleBackdrop open={spinner} />}
      <div className="feature-card-top-border" />
      <div className="feature-card-header">
        <div className="purple-head-left-border" />
        <span className="feature-card-title">{title}</span>
      </div>
      <div className="feature-card-body-tags-date-flex">
        <div className="feature-card-body-tags-flex">
          {author?.slice(0, 3).map((item) => (
            <div
              style={{ cursor: "pointer" }}
              className="feature-card-body-tag"
              key={item.authorId}
              onClick={() =>
                window
                  .open(
                    `/search?type=author&authorId=${item.authorId}&authorName=${item.name}`,
                    "_blank",
                  )
                  .focus()
              }
            >
              <Icon icon="bi:person-fill" color="#3E3F66" />
              <Tooltip title={item?.name}>
                <span>{shortenName(item.name, 9)}</span>
              </Tooltip>
              <Icon icon="gridicons:external" color="#3E3F66" />
            </div>
          ))}
          {citationCount !== 0 && (
            <div className="feature-card-body-tag">
              <Icon icon="ion:newspaper-sharp" color="#3E3F66" />
              <span> Citation Count: {citationCount}</span>
            </div>
          )}
          {date && (
            <div className="feature-card-body-tag">
              <Icon icon="ion:calendar" color="#3E3F66" />
              <span>{date}</span>
            </div>
          )}
        </div>
        <div
          className="start-understandin-btn"
          // style={{ minWidth: "166px", maxHeight: "20px" }}
          onClick={() => uploadPDFHandler(paperId)}
        >
          <span className="start-understanding-btn-title">
            Start Understanding
          </span>
          <Icon icon="ph:arrow-right-bold" color="white" />
        </div>
      </div>
      <div className="feature-card-divider" />
      <div className="features-desc">
        <span>
          {abbreviatedDescription}{" "}
          {description.length !== 0 && (
            <span
              className="feature-card-expand"
              onClick={() => setExpand(!expand)}
            >
              {expand ? "Show less" : "Show more"}
            </span>
          )}
        </span>
      </div>
      {/* <div className="research-card-footer">
        
      </div> */}
      <div className="feature-card-bottom-border" />
    </div>
  );
};

export default FeatureCard;
